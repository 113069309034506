import React from 'react'
import Footer from '../Footer/Footer'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './Tuscartas.module.css'

const Tuscartas = () => {
  return (
    <>
      <CatlotusNav/>
      <LowerNavbar/>
      <div className={styles.cum}>
        <div className={styles.cumas1}>Compramos tus cartas</div>
        <div className={styles.cumpas2} style={{marginTop:"9px"}}>¿Tienes cartas que ya no usas? ¿Te aburriste del mazo que tenías? O simplemente te falta un poco para completar tu nuevo commander</div>
        <div className={styles.cumpas2}>En Cat Lotus también compramos cartas, mazos y colecciones. Puedes enviarnos la lista de cartas que tienes para vender a contacto@catlotus.cl para que te hagamos una oferta. Ofrecemos un bono adicional si lo prefere como crédito de tienda.</div>
        <div className={styles.cumpas2} style={{marginBottom:"16px"}}>La oferta queda sujeta a revisión una vez que recibamos las cartas. Recibimos lotes desde los 200 dólares</div>
      </div>
      <Footer/>
    </>
  )
}

export default Tuscartas
