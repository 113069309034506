import React, { useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./AdminSolicitudesSL.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Footer from "../Footer/Footer";
import UserInfoModal from "../AdminSolicitudes/UserInfoModal";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { serverInstance } from "../../axiosInstance";
import { useEffect } from "react";
function PopupModal({
  showModal,
  handleCloseModal,
  handleSubmit,
  textAreaValue,
  handleTextAreaChange,
}) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTextarea">
            <Form.Label>notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
const AdminSolicitudesSL = () => {
  const [tabchange, setTabchange] = useState(1);
  const [first, setFirst] = useState([]);
  const [two, setTwo] = useState([]);
  const [three, setThree] = useState([]);
  const [four, setFour] = useState([]);
  const [five, setFive] = useState([]);
  const [six, setSix] = useState([]);
  const [detailOne, setDetailOne] = useState();
  const [showModal1, setShowModal1] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [blue, setBlue] = useState();
  const [orderId, setOrderId] = useState();
  const [srep, setSrep] = useState();
  const handleSubmittt = (e) => {
    e.preventDefault();

    // Filter the data based on user input
    const filteredData = five?.filter((item) => {
      const isIdMatch = id === "" || item.id.toString() === id;
      const isNameMatch =
        name === "" || item.nombre.toLowerCase().includes(name.toLowerCase());
      const isLastNameMatch =
        lastName === "" ||
        item.apellido.toLowerCase().includes(lastName.toLowerCase());
      const isEmailMatch =
        email === "" || item.mail.toLowerCase().includes(email.toLowerCase());

      return isIdMatch && isNameMatch && isLastNameMatch && isEmailMatch;
    });

    // Update the filtered results
    setFilteredResults(filteredData);
  };
  const handleSubmittt1 = (e) => {
    e.preventDefault();

    // Filter the data based on user input
    const filteredData = six?.filter((item) => {
      const isIdMatch = id === "" || item.id.toString() === id;
      const isNameMatch =
        name === "" || item.nombre.toLowerCase().includes(name.toLowerCase());
      const isLastNameMatch =
        lastName === "" ||
        item.apellido.toLowerCase().includes(lastName.toLowerCase());
      const isEmailMatch =
        email === "" || item.mail.toLowerCase().includes(email.toLowerCase());

      return isIdMatch && isNameMatch && isLastNameMatch && isEmailMatch;
    });

    // Update the filtered results
    setFilteredResults(filteredData);
  };
  // Function to handle form submission

  const openModal = () => setShowModal1(true);
  const closeModal = () => setShowModal1(false);
  const [showModal, setShowModal] = useState(false);

  const [textAreaValue, setTextAreaValue] = useState("");
  const [inttValues, setInttValues] = useState(Array(four?.length).fill(""));
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTextAreaValue("");
  };

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
  };
  const handleInputChange = (itemId, value) => {
    // Update the 'intt' value for the selected item
    const updatedInttValues = [...inttValues];
    updatedInttValues[itemId] = value;
    setInttValues(updatedInttValues);
  };
  const handleSubmit = () => {
    console.log("Textarea Value:", textAreaValue);
    serverInstance
      .post("/user/updatenotessl", {
        nota: textAreaValue,
        id: detailOne.id,
      })
      .then((res) => {
        // Swal.fire("Added succesfully", "", "success");
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });

    handleCloseModal();
  };
  const handleSubmity = (id, index) => {
    if (inttValues[index] == "") {
      return Swal.fire("Enter value", "", "error");
    }
    serverInstance
      .post("/user/changeNroSeguimientosl", {
        nroSeguimiento: inttValues[index],
        id: id,
      })
      .then((res) => {
        handleInputChange(index, inttValues[index]);
        // Swal.fire("Added succesfully", "", "success");
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });

    handleCloseModal();
  };
  useEffect(() => {
    One();
  }, []);
  const One = () => {
    serverInstance
      .get("/user/solicitudes_sl/0")
      .then((res) => {
        setFirst(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Two = () => {
    serverInstance
      .get("/user/solicitudes_sl/1")
      .then((res) => {
        setTwo(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Three = () => {
    serverInstance
      .get("/user/solicitudes_sl/2")
      .then((res) => {
        setThree(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Four = () => {
    serverInstance
      .get("/user/solicitudes_sl/3")
      .then((res) => {
        setFour(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        setInttValues(
          res?.data?.resultado?.result?.map((d) => d?.numero_seguimiento)
        );
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Five = () => {
    serverInstance
      .get("/user/solicitudes_sl/4")
      .then((res) => {
        setFive(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        setFilteredResults([]);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  const Six = () => {
    serverInstance
      .get("/user/solicitudes_sl/5")
      .then((res) => {
        setSix(res?.data?.resultado?.result);
        var listRepEmails = [];
        var blueMails = [];
        if (res?.data?.resultado?.mailsrep !== undefined) {
          for (
            var i = 0;
            i < Object.keys(res?.data?.resultado?.mailsrep).length;
            i++
          ) {
            listRepEmails.push(res?.data?.resultado?.mailsrep[i].mail);
          }
        }
        if (res?.data?.resultado?.blue_mails !== undefined) {
          for (var i = 0; i < res?.data?.resultado?.blue_mails?.length; i++) {
            blueMails.push(res?.data?.resultado?.blue_mails[i]);
          }
        }
        setBlue(blueMails);
        setSrep(listRepEmails);
        console.log(res);
        setFilteredResults([]);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };

  const confirmOne = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setConfirmadasl/${id}`)
          .then((res) => {
            One();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const empphaTwo = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setEmpacadasl2/${id}`)
          .then((res) => {
            Two();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const confirmThree = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setEmpacadasl/${id}`)
          .then((res) => {
            Three();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const confirmFour = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setEnviadasl/${id}`)
          .then((res) => {
            Four();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const cancelOne = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/setRechazadasl/${id}`)
          .then((res) => {
            One();
            console.log(res);
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const addressOne = (e, id) => {
    e.preventDefault();
    Swal.fire({
      text: "¿Estás seguro que deseas realizar esta acción?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        serverInstance
          .get(`/user/solicitudes/${id}`)
          .then((res) => {
            // One();
            console.log(res);
            // setDetailOne(res.data.resultado.)
          })
          .catch((err) => {
            console.log("in error", err);
          });
      }
    });
  };
  const tabHandle = () => {
    setTabchange(1);
    One();
  };
  const tabHandle1 = () => {
    setTabchange(2);
    Two();
  };
  const tabHandle2 = () => {
    setTabchange(3);
    Three();
  };
  const tabHandle3 = () => {
    setTabchange(4);
    Four();
  };
  const tabHandle4 = () => {
    setTabchange(5);
    Five();
    setFilteredResults([]);
  };
  const tabHandle5 = () => {
    setTabchange(6);
    Six();
    setFilteredResults([]);
  };

  return (
    <>
      <PopupModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        textAreaValue={textAreaValue}
        handleTextAreaChange={handleTextAreaChange}
      />
      <UserInfoModal show={showModal1} onClose={closeModal} data={detailOne} />
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.order}>
        <div className={styles.orderInner}>
          <div className={styles.topButons}>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 1 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle}
            >
              Compras pendientes
            </button>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 2 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle1}
            >
              Compras confirmadas
            </button>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 3 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle2}
            >
              Pedidos en camino
            </button>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 4 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle3}
            >
              Compras recepcionadas
            </button>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 5 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle4}
            >
              Compras enviadas
            </button>
            <button
              className={styles.secondbutton}
              style={
                tabchange == 6 ? { color: "white", background: "#000" } : {}
              }
              onClick={tabHandle5}
            >
              Compras rechazadas
            </button>
          </div>
          <div className={styles.searchArea}>
            <div className={styles.textSaecr}>Search Orders</div>
            <input
              type="text"
              className={styles.serInput}
              value={orderId}
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
            />
          </div>
          <div className={styles.lowerTable}>
            {tabchange === 1 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>

                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Confirmar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Cancelar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Dirección</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalle</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {first
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>

                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                confirmOne(e, row?.id);
                              }}
                            >
                              Confirmar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                cancelOne(e, row?.id);
                              }}
                            >
                              Cancelar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 2 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>

                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Empacar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Direccion</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalles </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Notas</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {two
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                empphaTwo(e, row?.id);
                              }}
                            >
                              En transito
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 3 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>

                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Empacar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Direccion</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalles </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Notas</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {three
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                confirmThree(e, row?.id);
                              }}
                            >
                              Empacar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 4 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> ID</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Email</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Monto</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Credito</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Fecha de compra</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Enviar</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}>
                          {" "}
                          Número de seguimiento
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Dirección</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Detalle</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Notas</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {four
                      ?.filter((row) =>
                        orderId
                          ? String(row?.id)?.includes(String(orderId))
                          : true
                      )
                      .map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              ${row?.credito}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confi}
                              onClick={(e) => {
                                confirmFour(e, row?.id);
                              }}
                            >
                              Confirmar
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.confi1}>
                              <input
                                type="number"
                                placeholder="0"
                                className={styles.inCam}
                                value={inttValues[index]}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              />
                              <button
                                style={{ marginTop: "5px" }}
                                onClick={(e) => {
                                  handleSubmity(row?.id, index);
                                }}
                              >
                                Cambiar
                              </button>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tabchange === 5 && (
              <>
                {" "}
                <div style={{ marginTop: "5%" }}>
                  <label htmlFor="AceptadaID">Search by ID</label>
                  <input
                    type="text"
                    placeholder="id"
                    name="aceptadaid"
                    id="AceptadaID"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                  <button onClick={handleSubmittt}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaNombre">Search by Name</label>
                  <input
                    type="text"
                    placeholder="name"
                    name="aceptadanombre"
                    id="AceptadaNombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <button onClick={handleSubmittt}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaApellido">Search by Last Name</label>
                  <input
                    type="text"
                    placeholder="last name"
                    name="aceptadaapellido"
                    id="AceptadaApellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <button onClick={handleSubmittt}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaCorreo">Search by Email</label>
                  <input
                    type="text"
                    placeholder="mail"
                    name="aceptadacorreo"
                    id="AceptadaCorreo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={handleSubmittt}>Look for</button>

                  <br />
                  <button
                    onClick={() => {
                      setFilteredResults(five);
                    }}
                  >
                    Load all
                  </button>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tdd">
                        <TableCell align="right">
                          <div className={styles.topRow}> ID</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Nombre</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Apellido</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Email</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Monto</div>
                        </TableCell>

                        <TableCell align="right">
                          <div className={styles.topRow}> Fecha de compra</div>
                        </TableCell>

                        <TableCell align="right">
                          <div className={styles.topRow}>
                            {" "}
                            Número de seguimiento
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Dirección</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Detalle</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Notas</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredResults?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>

                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>

                          <TableCell align="right">
                            <div className={styles.confi1}>
                              <input
                                type="number"
                                placeholder="0"
                                className={styles.inCam}
                                value={inttValues[index]}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              />
                              <button
                                style={{ marginTop: "5px" }}
                                onClick={(e) => {
                                  handleSubmity(row?.id, index);
                                }}
                              >
                                Cambiar
                              </button>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.detl}
                              onClick={() => {
                                setDetailOne(row);
                                handleOpenModal();
                                setTextAreaValue(row?.notas);
                              }}
                              style={
                                row?.notas
                                  ? { background: "red", color: "white" }
                                  : {}
                              }
                            >
                              Ver
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {tabchange === 6 && (
              <>
                {" "}
                <div style={{ marginTop: "5%" }}>
                  <label htmlFor="AceptadaID">Search by ID</label>
                  <input
                    type="text"
                    placeholder="id"
                    name="aceptadaid"
                    id="AceptadaID"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                  <button onClick={handleSubmittt1}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaNombre">Search by Name</label>
                  <input
                    type="text"
                    placeholder="name"
                    name="aceptadanombre"
                    id="AceptadaNombre"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <button onClick={handleSubmittt1}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaApellido">Search by Last Name</label>
                  <input
                    type="text"
                    placeholder="last name"
                    name="aceptadaapellido"
                    id="AceptadaApellido"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <button onClick={handleSubmittt1}>Look for</button>

                  <br />

                  <label htmlFor="AceptadaCorreo">Search by Email</label>
                  <input
                    type="text"
                    placeholder="mail"
                    name="aceptadacorreo"
                    id="AceptadaCorreo"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={handleSubmittt1}>Look for</button>

                  <br />
                  <button
                    onClick={() => {
                      setFilteredResults(six);
                    }}
                  >
                    Load all
                  </button>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tdd">
                        <TableCell align="right">
                          <div className={styles.topRow}> ID</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Nombre</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Apellido</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Email</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Monto</div>
                        </TableCell>

                        <TableCell align="right">
                          <div className={styles.topRow}> Fecha de compra</div>
                        </TableCell>

                        <TableCell align="right">
                          <div className={styles.topRow}> Dirección</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.topRow}> Detalle</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredResults?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.id}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>{row?.nombre}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.apellido}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={styles.tabData}
                              style={{
                                color:
                                  blue !== undefined &&
                                  blue?.includes(row?.mail) &&
                                  srep !== undefined &&
                                  srep?.includes(row?.mail)
                                    ? "green"
                                    : blue !== undefined &&
                                      blue?.includes(row?.mail)
                                    ? "blue"
                                    : srep !== undefined &&
                                      srep?.includes(row?.mail)
                                    ? "red"
                                    : "",
                              }}
                            >
                              {row?.mail}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.tabData}>${row?.monto}</div>
                          </TableCell>

                          <TableCell align="right">
                            <div className={styles.tabData}>
                              {row?.fecha_compra}
                            </div>
                          </TableCell>

                          <TableCell align="right">
                            <div
                              className={styles.confiblue}
                              onClick={() => {
                                setDetailOne(row);
                                openModal();
                              }}
                            >
                              Dirección
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={"/OrderDetailsl"}
                              state={{
                                id: row?.id,
                              }}
                            >
                              <div className={styles.detl}>Detalle</div>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminSolicitudesSL;
