import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Footer from "../Footer/Footer";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "../Deckbuilder3/Deckbuilder3.module.css";
import CenterCard from "../Images/CenterCard.png";
import kann2 from "../Images/kann2.png";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { serverInstance } from "../../axiosInstance";
//rare
import common from "../Images/rarity/Comun.png";
import mythic from "../Images/rarity/Mitica.png";
import uncommon from "../Images/rarity/Infrecuente.png";
import rare from "../Images/rarity/Rara.png";
import Promocional from "../Images/rarity/Promocional.png";
//foil
import foil from "../Images/search/foil.png";
import etched from "../Images/search/etched.png";
import oversized from "../Images/search/oversized.png";
import oversized_foil from "../Images/search/oversized_foil.png";
import gilded from "../Images/search/gilded.png";
import texturized from "../Images/search/texturized.png";
import surge from "../Images/search/surge.png";
import galaxy from "../Images/search/galaxy.png";
import oil_slick from "../Images/search/oil_slick.png";
import signed from "../Images/search/signed.png";
import step_and_compleat from "../Images/search/step-and-compleat.png";
import halo from "../Images/search/halo.png";
import confetti from "../Images/search/confetti.png";
import double_rainbow from "../Images/search/DOUBLE RAINBOW.png";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { setCartItem } from "../../Redux/adminReducer";
import { Link, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import Empt from "./Empty";
import { BiZoomIn } from "react-icons/bi";
import sin_stock from "../Images/search/SIN STOCK.png";

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, `%27`);
}
const Hojear = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state?.AdminReducer?.cartItems);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  }, []);
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [search, setSearch] = useState(false);
  const [stock, setStock] = useState({});
  const [values, setValues] = useState({});
  const handleIncrementDecrement = (key, index, operation, q) => {
    console.log(key, index, operation);
    setValues((prevCounts) => {
      const updatedCounts = { ...prevCounts };

      if (!updatedCounts[key]) {
        updatedCounts[key] = Array(stock[key].length).fill(0);
      }

      if (operation === "increment") {
        if (values[key]) {
          if (q > values[key][index]) updatedCounts[key][index] += 1;
        } else {
          updatedCounts[key][index] += 1;
        }
      } else if (operation === "decrement") {
        if (values[key]) {
          if (values[key][index] > 0) updatedCounts[key][index] -= 1;
        }
      }
      return { ...updatedCounts };
    });
  };
  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      const pathParts = item.split(".");
      const extension = pathParts[pathParts.length - 1];
      const name = pathParts[pathParts.length - 2];
      const src = r(item);
      images[name] = {
        name: name,
        extension: extension,
        src: src,
      };
      console.log(`Name: ${name}, Extension: ${extension}`);
    });
    return images;
  };

  const manaImages = importAll(
    require.context("../Images/mana", false, /\.(png|jpe?g|svg)$/)
  );
  const [magnifiedImages, setMagnifiedImages] = useState({});

  const handleZoomClick = (key) => {
    setMagnifiedImages((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the magnified state for the corresponding key
    }));
  };
  useEffect(() => {
    let count = 0;
    let totalPrice = 0;
    // for (const value of Object.values(values)) {
    //   count += value.filter((val) => val > 0).length;
    // }
    {
      values &&
        Object.keys(values).map((key) => {
          values[key].map((d, i) => {
            if (d > 0) {
              const item = stock[key][i];
              totalPrice += item.precio * d;
              count += d;
            }
          });
        });
    }
    setPrice(totalPrice);
    setQuantity(count);
    console.log(count, "updatedCounts");
  }, [values]);
  const handleClick = (search) => {
    setSearch(true);
    setStock({});
    setShowOffcanvas(false);
    serverInstance
      .post("/details/hojear", { method: search })
      .then((res) => {
        console.log(res.data);
        setStock(res.data.stock);
        const totalLength = Object.values(res.data.stock).reduce(
          (length, arr) => {
            if (Array.isArray(arr)) {
              length += arr.length;
            }
            return length;
          },
          0
        );

        console.log(totalLength);
        setSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCart = () => {
    if (quantity > 0) {
      let newCart = [];
      values &&
        Object.keys(values).map((key) => {
          values[key].map((d, i) => {
            if (d > 0) {
              let item = stock[key][i];
              item = { ...item, quantity: d };
              newCart.push(item);
            }
          });
        });
      const mergedArray = [...cartItems];

      newCart.forEach((item) => {
        const index = mergedArray.findIndex(
          (element) => element.idcarta === item.idcarta
        );

        if (index === -1) {
          // If the idcarta doesn't exist in arr1, push the item
          mergedArray.push(item);
        }
      });

      dispatch(setCartItem(mergedArray));
      navigate("/cart2");
    } else {
      Swal.fire("select a card", "", "error");
    }
  };
  const generateCardLink = (card) => {
    // Split the card name into two parts using " // "
    const cardNameParts = card?.nombre?.split(" // ");

    // Encode each part of the card name

    const identifier =
      cardNameParts.length > 1 ? "multiple-parts" : "single-part";

    // Combine the encoded parts, identifier, set name, and collector number into the final link

    let cardLink = null;
    if (cardNameParts?.length > 1) {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
      console.log(cardLink?.split("/")[5]);
    } else {
      cardLink =
        "/cardview/" +
        fixedEncodeURIComponent(card?.set.toLowerCase()) +
        "/" +
        fixedEncodeURIComponent(card?.nombre.toLowerCase()) +
        "/" +
        (card?.collector_number?.length > 0
          ? card?.collector_number
          : "default") +
        "/" +
        identifier;
    }
    return cardLink;
  };
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  return (
    <div>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.builderrr}>
        <div className={styles.builder}>
          {["lg"].map((expand) => (
            <Navbar key={expand} expand={expand} className={styles.centerKp}>
              <Container fluid className={styles.namfluid}>
                <Navbar.Brand href="#"> </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  className={styles.togleBorder}
                  onClick={() => setShowOffcanvas(!showOffcanvas)}
                >
                  <div className={styles.carro1}>Hojear </div>
                </Navbar.Toggle>
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                  show={showOffcanvas}
                  onHide={() => setShowOffcanvas(false)}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <div className={styles.leftBuilder}>
                        <div className={styles.headingArea}>
                          <div className={styles.heading1}>Hojear </div>
                          <div className={styles.detail}>
                            Selecciona una de las siguientes opciones
                          </div>
                        </div>
                        <div style={{ marginTop: "24px" }}></div>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("mas_vendidas");
                          }}
                        >
                          Más vendidas
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("base_commander");
                          }}
                        >
                          Base commander
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("tokens");
                          }}
                        >
                          Tokens
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("mas_valiosas");
                          }}
                        >
                          Más valiosas
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("tierras_promo");
                          }}
                        >
                          Tierras promo
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("tierras_nevadas");
                          }}
                        >
                          Tierras nevadas
                        </button>
                        <button
                          className={styles.aplicar}
                          onClick={(e) => {
                            handleClick("alpha_beta_unlimited");
                          }}
                        >
                          Alpha / Beta / Unlimited
                        </button>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
          <div className={styles.rightBuilder}>
            <div className={styles.lowerBuutons} style={{ height: "100%" }}>
              {Object.keys(stock).length === 0 ? (
                <>
                  {search ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <BallTriangle
                        height={150}
                        width={150}
                        radius={3}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <Empt />
                  )}
                </>
              ) : (
                <>
                  <button className={styles.cartasBuuton}>
                    Cartas seleccionadas:{quantity}
                  </button>
                  <button className={styles.totalButton}>
                    Total: $ {price.toLocaleString("es-CL")}
                  </button>
                  <button className={styles.agregar} onClick={handleCart}>
                    Agregar al carro
                  </button>
                </>
              )}
            </div>
            <div
              className={styles.centerArea}
              style={search ? { display: "none" } : {}}
            >
              {Object.keys(stock).map((key) => {
                const isMagnified = magnifiedImages[key] || false;
                return (
                  <>
                    <div className={styles.upperCards}>
                      <div className={`${styles.lore} `}>
                        <div
                          className="uppppp"
                          style={{ position: "relative", paddingLeft: "20px" }}
                        >
                          <BiZoomIn className="zome" />
                          <img
                            onClick={() => handleZoomClick(key)}
                            src={
                              isMagnified
                                ? stock[key][0]?.image_uris?.large
                                : stock[key][0]?.image_uris?.small
                            }
                            className={`centerImageee ${
                              isMagnified ? "magnifier" : ""
                            }`}
                            alt=""
                          />
                        </div>

                        <div
                          className={`${styles.umezawa} col-md-8 col-lg-8 ${
                            stock[key][0]?.nombre?.length > 40
                              ? "col-xl-3"
                              : "col-xl-3"
                          }`}
                        >
                          <div className={styles.umeHeading}>
                            <Link
                              to={generateCardLink(stock[key][0])}
                              state={{
                                idd: "",
                              }}
                              // to={`/cardview/${fixedEncodeURIComponent(
                              //   stock[key][0].set.toLowerCase()
                              // )}/${fixedEncodeURIComponent(
                              //   stock[key][0].nombre.toLowerCase()
                              // )}/${
                              //   stock[key][0].collector_number !== ""
                              //     ? stock[key][0].collector_number
                              //     : "default"
                              // }`}
                            >
                              {stock[key][0]?.nombre}
                            </Link>
                          </div>
                          <div className={styles.umeFrom}>
                            {stock[key][0]?.set_name}
                          </div>
                          {stock[key][0]?.rarity !== undefined ? (
                            stock[key][0]?.rarity === "common" ? (
                              <img
                                src={common}
                                className={styles.kann2}
                                alt=""
                              />
                            ) : stock[key][0]?.rarity === "mythic" ? (
                              <img
                                src={mythic}
                                className={styles.kann2}
                                alt=""
                              />
                            ) : stock[key][0]?.rarity === "uncommon" ? (
                              <img
                                src={uncommon}
                                className={styles.kann2}
                                alt=""
                              />
                            ) : stock[key][0]?.rarity === "rare" ? (
                              <img src={rare} className={styles.kann2} alt="" />
                            ) : (
                              <img
                                src={Promocional}
                                className={styles.kann2}
                                alt=""
                              />
                            )
                          ) : (
                            ""
                          )}
                          <div className={styles.numbu}>
                            {stock[key][0]?.mana_cost?.map((d, index) => {
                              // Remove any non-alphanumeric characters (except for '/')
                              const cleanD = d?.replace(/[^A-Za-z0-9/]/g, "");
                              console.log(d, "deckk", cleanD);

                              return (
                                <React.Fragment key={index}>
                                  {/* Check if the mana cost contains '//' */}
                                  {cleanD.includes("//") ? (
                                    cleanD
                                      .split("//")
                                      .map((part, partIndex) => {
                                        const trimmedPart = part.trim();
                                        return (
                                          <React.Fragment key={partIndex}>
                                            {manaImages[`/${trimmedPart}`] ? (
                                              <img
                                                src={
                                                  manaImages[`/${trimmedPart}`]
                                                    .src
                                                }
                                                alt={`Mana Symbol ${trimmedPart} alt`}
                                                style={{
                                                  width: "25px",
                                                  height: "25px",
                                                  margin: "0px 1px",
                                                }}
                                              />
                                            ) : (
                                              <span>{trimmedPart}</span>
                                            )}
                                            {partIndex <
                                              cleanD.split("//").length - 1 && (
                                              <span> // </span>
                                            )}
                                          </React.Fragment>
                                        );
                                      })
                                  ) : (
                                    <>
                                      {manaImages[
                                        `/${cleanD?.replace("/", "")}`
                                      ] ? (
                                        <img
                                          src={
                                            manaImages[
                                              `/${cleanD?.replace("/", "")}`
                                            ].src
                                          }
                                          alt={`Mana Symbol ${cleanD?.replace(
                                            "/",
                                            ""
                                          )} alt`}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            margin: "0px 1px",
                                          }}
                                        />
                                      ) : (
                                        <span>{cleanD}</span>
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          {/* <div className={styles.numbu}>
                            {stock[key][0]?.mana_cost}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className={styles.centerCard}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          {" "}
                          <TableHead>
                            <TableRow className="tdd">
                              <TableCell align="right">
                                <div className={styles.topDesign}>Estado</div>{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.topDesign}>Idioma</div>{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.topDesign}>
                                  Tratamiento foil
                                </div>{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.topDesign}>Stock</div>{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.topDesign}>Precio</div>{" "}
                              </TableCell>
                              <TableCell align="right">
                                <div className={styles.topDesign}>Agregar</div>{" "}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {stock[key]?.map((row, index) => (
                            <TableBody>
                              <TableRow
                                key={row?.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="right">
                                  <div className="d-flex justify-content-center">
                                    <div className={styles.num}>
                                      {row?.estado == 0
                                        ? "NM"
                                        : row?.estado == 1
                                        ? "SP"
                                        : row?.estado == 2
                                        ? "MP"
                                        : row?.estado == 3
                                        ? "HP"
                                        : row?.estado == 4
                                        ? "DMG"
                                        : row?.estado == 5
                                        ? "AL"
                                        : ""}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <div className="d-flex justify-content-center">
                                    <div className={styles.idio}>
                                      {(row?.idioma).toUpperCase()}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <div className="d-flex justify-content-center">
                                    <div className={styles.foils}>
                                      {row?.stock < values[key][index] ? (
                                        <img
                                          src={sin_stock}
                                          style={{ width: "100px" }}
                                        />
                                      ) : row?.foil >= 1 && row?.foil <= 13 ? (
                                        row?.foil === 1 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={foil}
                                          />
                                        ) : row?.foil === 2 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={etched}
                                          />
                                        ) : row?.foil === 3 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={oversized}
                                          />
                                        ) : row?.foil === 4 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={oversized_foil}
                                          />
                                        ) : row?.foil === 5 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={gilded}
                                          />
                                        ) : row?.foil === 6 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={texturized}
                                          />
                                        ) : row?.foil === 7 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={surge}
                                          />
                                        ) : row?.foil === 8 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={galaxy}
                                          />
                                        ) : row?.foil === 9 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={oil_slick}
                                          />
                                        ) : row?.foil === 10 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={step_and_compleat}
                                            alt="Step-and-Compleat"
                                          />
                                        ) : row?.foil === 11 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={halo}
                                            alt="Halo"
                                          />
                                        ) : row?.foil === 12 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={confetti}
                                            alt="Confetti"
                                          />
                                        ) : row?.foil === 13 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={double_rainbow}
                                            alt="Double Rainbow"
                                          />
                                        ) : row?.signed === 1 ? (
                                          <img
                                            style={{ width: "100px" }}
                                            src={signed}
                                          />
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {" "}
                                  <div className="d-flex justify-content-center">
                                    <div className={styles.dtovk}>
                                      {row?.stock - row?.reservadas}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ fontSize: "21px" }}
                                >
                                  <div className="d-flex justify-content-center">
                                    {" "}
                                    $ {(row?.precio).toLocaleString("es-CL")}
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <div className={styles.numberArea}>
                                    <div
                                      className={styles.decremet}
                                      style={
                                        0 == values[key] && values[key][index]
                                          ? { cursor: "default" }
                                          : {}
                                      }
                                      onClick={() =>
                                        handleIncrementDecrement(
                                          key,
                                          index,
                                          "decrement",
                                          row?.stock - row?.reservadas
                                        )
                                      }
                                    >
                                      -
                                    </div>
                                    <div className={styles.numberFielkd}>
                                      {values[key] ? values[key][index] : 0}
                                    </div>
                                    <div
                                      className={styles.increment}
                                      style={
                                        row?.stock - row?.reservadas ==
                                          values[key] && values[key][index]
                                          ? { cursor: "default" }
                                          : {}
                                      }
                                      onClick={() =>
                                        handleIncrementDecrement(
                                          key,
                                          index,
                                          "increment",
                                          row?.stock - row?.reservadas
                                        )
                                      }
                                    >
                                      +
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              className={styles.lowerBuutons}
              style={search ? { display: "none" } : {}}
            >
              {Object.keys(stock).length === 0 ? (
                ""
              ) : (
                <>
                  <button className={styles.cartasBuuton}>
                    Cartas seleccionadas:{quantity}
                  </button>
                  <button className={styles.totalButton}>
                    Total: $ {price.toLocaleString("es-CL")}
                  </button>
                  <button className={styles.agregar} onClick={handleCart}>
                    Agregar al carro
                  </button>
                </>
              )}
            </div>
          </div>
          {/* <Empty /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};
const Empty = () => {
  return (
    <>
      <div style={{ textAlign: "center", width: "100%", paddingTop: "60px" }}>
        <spam>
          <br style={{ lineHeight: "3rem" }} />
          Con esta herramienta podras buscar distintos grupos de cartas segun
          categorias preseleccionadas.
        </spam>
        <br />
        <br />
        <spam>
          Mas vendidas: Una selección de las cartas mas vendidas de la tienda.
        </spam>
        <br />
        <br />
        <spam>Base commander: Una base para cualquier mazo commander.</spam>
        <br />
        <br />
        <spam>Tokens: Todas (o casi todas) las tokens disponibles.</spam>
        <br />
        <br />
        <spam>
          Más valiosas: Una selección de las cartas más caras de la tienda.
        </spam>
        <br />
        <br />
        <spam>Tierras promo: Las tierras promocionales disponibles</spam>
        <br />
        <br />
        <spam>Tierras nevadas: Las tierras nevadas disponibles</spam>
        <br />
        <br />
        <spam>
          Alpha / Beta / Unlimited: Todas las cartas de las primeras 3 ediciones
          de Magic
        </spam>
        <br />
        <br />
      </div>
    </>
  );
};
export default Hojear;
