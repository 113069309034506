import React, { useState } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import styles from "./AdminUpload.module.css";
import { serverInstance } from "../../axiosInstance";
import axios from "axios";
import Swal from "sweetalert2";

const AdminUpload = () => {
  const [radioSelect, setRadioSelect] = useState();
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [banner, setBanner] = useState();
  const [link, setLink] = useState("");
  const [newSLForm, setNewSLForm] = useState({
    titulo: "",
    fotos: ["", "", "", "", "", "", "", "", "", ""],
    desc: "",
    precios: [
      { price: "", pname: "" },
      { price: "", pname: "" },
      { price: "", pname: "" },
      { price: "", pname: "" },
    ],
    inicio: "",
    termino: "",
    estimada: "",
    cartas: "",
  });
  const handleNewSLClick = () => {
    setNewSLForm({
      titulo: "",
      fotos: ["", "", "", "", "", "", "", "", "", ""],
      desc: "",
      precios: [
        { price: "", pname: "" },
        { price: "", pname: "" },
        { price: "", pname: "" },
        { price: "", pname: "" },
      ],
      inicio: "",
      termino: "",
      estimada: "",
      cartas: "",
    });
  };
  const handleTituloChange = (e) => {
    setNewSLForm({ ...newSLForm, titulo: e.target.value });
  };
  const handleFotoChange = (index, e) => {
    const newFotos = [...newSLForm.fotos];
    newFotos[index] = e.target.files[0];
    setNewSLForm({ ...newSLForm, fotos: newFotos });
  };

  const handleDescChange = (e) => {
    setNewSLForm({ ...newSLForm, desc: e.target.value });
  };

  const handlePrecioChange = (index, field, e) => {
    const newPrecios = [...newSLForm.precios];
    newPrecios[index][field] = e.target.value;
    setNewSLForm({ ...newSLForm, precios: newPrecios });
  };

  const handleInicioChange = (e) => {
    setNewSLForm({ ...newSLForm, inicio: e.target.value });
  };

  const handleTerminoChange = (e) => {
    setNewSLForm({ ...newSLForm, termino: e.target.value });
  };

  const handleEstimadaChange = (e) => {
    setNewSLForm({ ...newSLForm, estimada: e.target.value });
  };

  const handleCartasChange = (e) => {
    setNewSLForm({ ...newSLForm, cartas: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !newSLForm.titulo ||
      newSLForm.fotos.length === 0 ||
      !newSLForm.estimada ||
      !newSLForm.termino ||
      !newSLForm.inicio ||
      !newSLForm.precios ||
      newSLForm.precios.length === 0
    ) {
      // Handle validation error - one or more compulsory fields are empty
      console.log("Compulsory fields are empty");
      Swal.fire("Compulsory fields are empty", "", "error");
      return;
    } else {
      let data;
      data = new FormData();

      data.append("titulo", newSLForm.titulo);

      newSLForm.fotos.forEach((foto, index) => {
        data.append(`files`, foto);
      });

      data.append("desc", newSLForm.desc);

      newSLForm.precios.forEach((precio, index) => {
        data.append(`precios[${index}][price]`, precio.price);
        data.append(`precios[${index}][pname]`, precio.pname);
      });

      data.append("inicio", newSLForm.inicio);
      data.append("termino", newSLForm.termino);
      data.append("estimada", newSLForm.estimada);
      data.append("cartas", newSLForm.cartas);
      console.log(data);
      console.log(newSLForm);

      await serverInstance
        .post("/secret/upload", data)
        .then((response) => {
          console.log("Response:", response.data);
          handleNewSLClick();
          Swal.fire("Added successfully", "", "success");
          window.location.reload();
        })
        .catch((error) => {
          console.log("Error:", error);
          Swal.fire("something went wrong", "", "error");
        });
    }
  };
  const handleBanner = async (e) => {
    e.preventDefault();
    let data;
    data = new FormData();

    data.append("files", banner);

    await serverInstance
      .post("/secret/banner", data)
      .then((response) => {
        console.log("Response:", response.data);
        Swal.fire("Added successfully", "", "success");
      })
      .catch((error) => {
        console.log("Error:", error);
        Swal.fire("something went wrong", "", "error");
      });
  };
  const handleLink = async (e) => {
    e.preventDefault();

    await serverInstance
      .post("/secret/link", { link: link })
      .then((response) => {
        console.log("Response:", response.data);
        Swal.fire("Added successfully", "", "success");
      })
      .catch((error) => {
        console.log("Error:", error);
        Swal.fire("something went wrong", "", "error");
      });
  };
  const radioHandle = () => {
    console.log("radioHandle");
    setRadioSelect(1);
  };
  const radioHandle1 = () => {
    console.log("radioHandle");
    setRadioSelect(2);
  };

  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            <div className={styles.heading}>¿Que Quieres hacer?</div>
            <div className={styles.crear}>
              <button className={styles.crearbtn} onClick={radioHandle}>
                Crear un nuevo SecretLair
              </button>
              <button className={styles.crearbtn} onClick={radioHandle1}>
                Cambiar banner/link
              </button>
            </div>
            {radioSelect === 1 && (
              <div className={styles.informationSection}>
                <div className={styles.subaHead}>
                  Sube la información del secret lair
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Titulo:</div>
                  <input
                    type="text"
                    className={styles.tituInput}
                    value={newSLForm.titulo}
                    onChange={handleTituloChange}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 1:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(0, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 2:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(1, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 3:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(2, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 4:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(3, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 5:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(4, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 6:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(5, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 7:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(6, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 8:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(7, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 9:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(8, e);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Foto 10:</div>
                  <input
                    type="file"
                    className={styles.tituIut}
                    accept="image/*"
                    onChange={(e) => {
                      handleFotoChange(9, e);
                    }}
                  />
                </div>
                <div className={styles.descriptionArea}>
                  <div className={styles.descripHeading}>Descripción:</div>
                  <textarea
                    name=""
                    id=""
                    rows="10"
                    className={styles.textWidth}
                    value={newSLForm?.desc}
                    onChange={handleDescChange}
                  ></textarea>
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Precio 1:</div>
                  <input
                    type="number"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(0, "price", e);
                    }}
                    value={newSLForm?.precios[0]["price"]}
                  />
                  <input
                    type="text"
                    placeholder="nombre del precio"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(0, "pname", e);
                    }}
                    value={newSLForm?.precios[0]["pname"]}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Precio 2:</div>
                  <input
                    type="number"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(1, "price", e);
                    }}
                    value={newSLForm?.precios[1]["price"]}
                  />
                  <input
                    type="text"
                    placeholder="nombre del precio"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(1, "pname", e);
                    }}
                    value={newSLForm?.precios[1]["pname"]}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Precio 3:</div>
                  <input
                    type="number"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(2, "price", e);
                    }}
                    value={newSLForm?.precios[2]["price"]}
                  />
                  <input
                    type="text"
                    placeholder="nombre del precio"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(2, "pname", e);
                    }}
                    value={newSLForm?.precios[2]["pname"]}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Precio 4:</div>
                  <input
                    type="number"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(3, "price", e);
                    }}
                    value={newSLForm?.precios[3]["price"]}
                  />
                  <input
                    type="text"
                    placeholder="nombre del precio"
                    className={styles.pric1}
                    onChange={(e) => {
                      handlePrecioChange(3, "pname", e);
                    }}
                    value={newSLForm?.precios[3]["pname"]}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>
                    Fecha de inicio preventa:
                  </div>
                  <input
                    type="datetime-local"
                    onChange={handleInicioChange}
                    value={newSLForm?.inicio}
                    className={styles.pric1}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Fecha de fin preventa:</div>
                  <input
                    type="datetime-local"
                    onChange={handleTerminoChange}
                    value={newSLForm?.termino}
                    className={styles.pric1}
                  />
                </div>
                <div className={styles.priceArea}>
                  <div className={styles.pricHead}>Fecha estimada envio:</div>
                  <input
                    type="datetime-local"
                    className={styles.pric1}
                    onChange={handleEstimadaChange}
                    value={newSLForm?.estimada}
                  />
                </div>
                <div className={styles.descriptionArea}>
                  <div className={styles.descripHeading}>Cartas:</div>
                  <textarea
                    name=""
                    id=""
                    rows="10"
                    className={styles.textWidth}
                    onChange={handleCartasChange}
                    value={newSLForm?.cartas}
                  ></textarea>
                </div>
                <button className={styles.upbtn} onClick={handleSubmit}>
                  Upload Files
                </button>
              </div>
            )}
            {radioSelect === 2 && (
              <div className={styles.informationSection}>
                <div className={styles.inputArea}>
                  <div className={styles.titu}>Banner:</div>
                  <input
                    type="file"
                    accept="image/*"
                    className={styles.tituIut}
                    onChange={(e) => {
                      setBanner(e.target.files[0]);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <button className={styles.submitBtn} onClick={handleBanner}>
                    Submit
                  </button>
                  <div className={styles.upBtn}>Upload</div>
                </div>
                <div className={styles.inputArea} style={{ marginTop: "15px" }}>
                  <div className={styles.titu}>Link:</div>
                  <input
                    type="text"
                    className={styles.tituIut}
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.inputArea}>
                  <button className={styles.submitBtn} onClick={handleLink}>
                    Submit
                  </button>
                  <div className={styles.upBtn}>Upload</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUpload;
