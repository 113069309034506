import React from "react";
import styles from "./Footer.module.css";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import comflo from "../Home/HomeImages/comflo.png";

const Footer = () => {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerInner}>
          <div className={styles.topFoot}>
            <div className={styles.leftNav}>
              <div className={styles.somosArea}>
                <div className={styles.somo1}>Quienes somos</div>
                <div className={styles.somo1}>FAQ’s</div>
                <div className={styles.somo1}>Polotica de privacidad</div>
                <div className={styles.somo1}>Costos de envio</div>
                <div className={styles.somo1}>Polotoia de cookies</div>
              </div>
              <div className={styles.secreee}>
                <div className={styles.somo1}>Acceso a mi cuenta</div>
                <div className={styles.somo1}>Secret Liar</div>
                <div className={styles.somo1}>Compramos tus cartas</div>
              </div>
            </div>
            <div className={styles.rightNav}>
              <div className={styles.estar1}>
                <div className={styles.estar}>¡Síguenos para estar al día!</div>
                <div className={styles.iconArea}>
                  <a href="https://www.instagram.com/catlotusmtg/?hl=en">
                    <div className={styles.insta}>
                      <BsInstagram className={styles.instaInner} />
                    </div>{" "}
                  </a>
                  <a href="https://www.facebook.com/mtgcatlotus/">
                    {" "}
                    <div className={styles.insta1}>
                      <FaFacebookF className={styles.instaInner} />
                    </div>{" "}
                  </a>
                </div>
              </div>
              <div className={styles.imagLotus}>
                <img src={comflo} className={styles.comflo} alt="" />
                <div className={styles.catLotus}>Cat Lotus</div>
              </div>
            </div>
          </div>
          <div className={styles.copyRight}>
            <div className={styles.copy}>
              © Cat Lotus - Todos los derechos reservados
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
