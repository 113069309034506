import React, { useState } from "react";
import styles from "./Login.module.css";
import logincat from "../Images/logincat.png";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";
import { setCurrentUser } from "../../Redux/userReducer";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  let name, value;

  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.email == "" || user.password == "") {
      Swal.fire("Fill all the fields", "", "error");
    } else {
      serverInstance
        .post("/user/login", user)
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem("userToken", res.data.token);
            const decodedToken = jwt_decode(res.data.token);
            dispatch(setCurrentUser(decodedToken));
            Swal.fire(res.data.message, "", "success");
            navigate("/cart2");
          } else {
            Swal.fire(res.data.message, "", "error");
          }
        })
        .catch((err) => {
          console.log("in error", err);
          Swal.fire(err.response.data.message, "", "error");
        });
    }
  };
  return (
    <div className={styles.login}>
      <div className={styles.leftPart}>
        <div className={styles.leftTextArea}>
          <div className={styles.loginBlack}>¡Bienvenido de vuelta!</div>
          <Link to="/register">
            <button className={styles.crearButton}>Crear cuenta</button>
          </Link>
          <div className={styles.tienes}>¿No tienes cuenta?</div>
        </div>
      </div>

      <div className={styles.fieldArea}>
        <form onSubmit={handleSubmit}>
          <div className={styles.tucenya}>Entra a tu cuenta</div>
          <input
            name="email"
            type="email"
            value={user.email}
            onChange={handleInput}
            placeholder="Correo"
            className={styles.correo}
          />
          <input
            name="password"
            type="password"
            value={user.password}
            onChange={handleInput}
            placeholder="Clave"
            className={styles.clave}
          />
          <div>
            <Link to="/loginCofirm" className={styles.deatil}>
              ¿Olvidaste tu clave?
            </Link>
          </div>
          <img src={logincat} className={styles.logincat} alt="" />
          <button className={styles.iniciar}>Iniciar sesión</button>{" "}
        </form>
      </div>
    </div>
  );
};

export default Login;
