import React from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./OrderDetail.module.css";
import Footer from "../Footer/Footer";
import { serverInstance } from "../../axiosInstance";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const OrderDetailSL = (props) => {
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState([]);
  const location = useLocation();
  const { id } = location?.state;
  const dataArray = Object.entries(first);
  const [total, setTotal] = useState(0);
  const [totalP, setTotalP] = useState(0);
  const keyLabels = {
    nombre: "Nombre",
    apellido: "Apellido",
    c_rut: "Rut",
    mail: "Correo",
    sucursal: "Sucursal",
    region: "Region",
    comuna: "Comuna",
    calle: "Calle",
    numero_c: "Numero",
    departamento: "Departamento",
    c_block: "Block",
    telefono: "Telefono",
    status: "Estado",
    numero_seguimiento: "Numero de Seguimiento",
    c_instrucciones: "Instrucciones",
  };
  const renderedData = Object.entries(keyLabels).map(([key, label]) => {
    const value = first[key] || ""; // Get the value from the data object, default to empty string if not found
    return (
      <TableRow key={key}>
        <TableCell align="right">
          <div className={styles.aboid}>{label}</div>
        </TableCell>
        <TableCell align="right">
          <div className={styles.aboid}>{value}</div>
        </TableCell>
      </TableRow>
    );
  });
  useEffect(() => {
    // Calculate total and totalP based on the 'row' array
    if (second) {
      let newTotal = 0;
      let newTotalP = 0;

      second.forEach((item) => {
        newTotal += Number(item?.cantidad);
        newTotalP += Number(item?.cantidad * item?.precio);
      });

      setTotal(newTotal);
      setTotalP(newTotalP);
    }
  }, [second]);
  useEffect(() => {
    One();
  }, [id]);
  const One = () => {
    serverInstance
      .get(`/user/detalleTablesl/${id}`)
      .then((res) => {
        setFirst(res?.data?.resultado[0]);
        setSecond(res?.data?.resultado);

        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };

  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.detailArea}>
        <div className={styles.detailIner}>
          <div className={styles.detailmost}>
            <div className={styles.uperArea}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> Datos</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Información</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {dataArray.map(([key, value], index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.aboid}>{key}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>{value}</div>
                        </TableCell>
                      </TableRow>
                    ))} */}
                    {renderedData}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow1}> Cantidad</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Precio individual</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Precio total</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Secret Lair</div>
                      </TableCell>

                      <TableCell align="right">
                        <div className={styles.topRow1}> Nombre</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {second?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.aboid}>{row?.cantidad}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            ${row?.precio?.toLocaleString("es-CL")}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            ${row?.monto?.toLocaleString("es-CL")}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>{row?.nombre}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.tipo_name?.toUpperCase()}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <p>Total de cartas: {total}</p>
                <p>SubTotal: ${totalP.toLocaleString("es-CL")}</p>
                <p>Total: ${totalP.toLocaleString("es-CL")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetailSL;
