import React from "react";
import styles from "./AdminHome.module.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import AdminSideBar from "../AdminSideBar/AdminSideBar";

const AdminHome = () => {
  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        {/* <div className={styles.logArea}>
                    <div className={styles.loginBox}>
                        <div className={styles.heading}>Login</div>
                        <div>
                            <div className={styles.mailTitlt}>Email</div>
                            <input type="email" className={styles.emailinput} />
                        </div>
                        <div>
                            <div className={styles.mailTitlt}>Password</div>
                            <input type="password" className={styles.emailinput} />
                        </div>
                        <button className={styles.submitt}>Submit</button>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default AdminHome;
