import React, { useState } from "react";
import styles from "./Register.module.css";
import RegisterCat from "../Images/RegisterCat.png";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { validateRut } from "@fdograph/rut-utilities";
const Register = () => {
  const [rut, setRut] = useState(true);
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    rpassword: "",
    birthday: "",
    phone: "",
    newsletter: "",
    rut: "",
  });

  let name, value;

  const handleInput = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  return (
    <div className={styles.rgister}>
      <div className={styles.registerLeft}>
        <div className={styles.registTitle}>Bienvenido a Cat Lotus</div>
        <div className={styles.poder}>
          Crea una cuenta para poder realizer pedidos Todos los campos son
          obligstions
        </div>
        <div className={styles.NmaeArea}>
          <input
            name="fname"
            type="name"
            value={user.fname}
            onChange={handleInput}
            placeholder="Nombre"
            className={styles.nombre}
          />
          <input
            name="lname"
            type="name"
            value={user.lname}
            onChange={handleInput}
            style={{ marginLeft: "5px" }}
            placeholder="Apellido"
            className={styles.nombre}
          />
        </div>
        <div className={styles.NmaeArea}>
          <input
            name="email"
            type="email"
            value={user.email}
            onChange={handleInput}
            placeholder="Correo"
            // className={styles.correo}
            className={styles.nombre}
          />{" "}
          <input
            name="phone"
            type="text"
            value={user.phone}
            onChange={handleInput}
            style={{ marginLeft: "5px" }}
            placeholder="Teléfono"
            className={styles.nombre}
          />
        </div>
        <div className={styles.NmaeArea}>
          <input
            name="password"
            type="password"
            value={user.password}
            onChange={handleInput}
            placeholder="Clave"
            className={styles.nombre}
          />
          <input
            name="rpassword"
            type="password"
            value={user.rpassword}
            onChange={handleInput}
            style={{ marginLeft: "5px" }}
            placeholder="Repetir clave"
            className={styles.nombre}
          />
        </div>
        <div className={styles.NmaeArea}>
          <input
            name="birthday"
            type="date"
            value={user.birthday}
            onChange={handleInput}
            placeholder="Feecha de nacimiento"
            className={styles.nombre}
          />
          <input
            name="rut"
            type="text"
            value={user.rut}
            onChange={(e) => {
              setUser({ ...user, rut: e.target.value });
              console.log(validateRut(e.target.value));
              setRut(validateRut(e.target.value));
            }}
            className={styles.nombre}
            placeholder="Rut"
            style={rut === false ? { border: "red", marginLeft: "5px" } : { marginLeft: "5px" }}


          />
        </div>
        <div className={styles.selects}>
          {/* <div className={styles.slectionArea}>
            <input type="checkbox" className={styles.checkIn} />
            <div className={styles.acepto}>
              Acepto los términos y condiciones
            </div>
          </div> */}
          {/* <div className={styles.slectionArea}>
            <input
              type="checkbox"
              className={styles.checkIn}
              onChange={(e) => {
                setUser({ ...user, newsletter: e.target.checked });
              }}
              value={user.newsletter}
            />
            <div className={styles.acepto}>
              Quiero recibir Newsletter con noticias
            </div>
          </div> */}
        </div>
        <button
          className={styles.crear}
          onClick={(e) => {
            e.preventDefault();

            if (
              user.fname == "" ||
              user.lname == "" ||
              user.email == "" ||
              user.password == "" ||
              user.rpassword == "" ||
              user.birthday == "" ||
              user.phone == "" ||
              user?.rut == ""
              // user.newsletter == ""
            ) {
              Swal.fire("Fill all the fields!", "", "error");
            } else {
              if (user.password !== user.rpassword) {
                return Swal.fire("password should be same", "", "error");
              } if (rut === false) {
                return Swal.fire("Enter a valid rut", "", "error")
              } else {
                serverInstance
                  .post("/user/registro", user)
                  .then((res) => {
                    console.log(res);
                    if (res.data.added) {
                      Swal.fire(res.data.message, "", "success");
                    } else {
                      Swal.fire(res.data.message, "", "error");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          }}
        >
          Crear cuenta
        </button>
      </div>
      <div className={styles.registerRight}>
        <div className={styles.rightHeading}>¡Hola Hummano!</div>
        <Link to="/login">
          {" "}
          <button className={styles.sesion}>Inicia sesión</button>
        </Link>
        <div className={styles.yaun}>¿Ya tienes cuenta?</div>
        <img src={RegisterCat} alt="" className={styles.RregisterCat} />
      </div>
    </div>
  );
};

export default Register;
