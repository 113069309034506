import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import EnhancedTable from "../PermisosTable/PermisosTable";
import styles from "./AdminPermisos.module.css";
import AdminModal from "./AdminModal";
import { serverInstance } from "../../axiosInstance";
const AdminPermisos = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    serverInstance
      .get("/admin/allUsers")
      .then((ress) => {
        console.log(ress?.data?.response);
        setUsers(ress?.data?.response);
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            {/* <button className={styles.adding}>Add Crear Usuario </button> */}
            <AdminModal setUsers={setUsers} />
            <EnhancedTable users={users} setUsers={setUsers} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPermisos;
