import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import CatlotusNav from "./Navbar/CatlotusNav";
import LowerNavbar from "./LowerNavbar/LowerNavbar";
import styles from "./Navbar/Navbar.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { serverInstance } from "../axiosInstance";
import Swal from "sweetalert2";
const Approve = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  useEffect(() => {
    const pathArray = pathname.split("/");
    console.log(pathArray[2], "pathArray");
    serverInstance
      .post(`/user/confirmar_cuenta/${pathArray[2]}`, {})
      .then((res) => {
        console.log(res.data);
        if (res.data.approved) {
          Swal.fire(res.data.message, "", "success");
          navigate("/login");
        } else {
          Swal.fire(res.data.message, "", "error");
          navigate("/register");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between min-vh-100">
      <div>
        <div className={styles.navBack}>
          <CatlotusNav />
        </div>
        <LowerNavbar />
      </div>
      <Footer />
    </div>
  );
};

export default Approve;
