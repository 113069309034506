import React from 'react'
import styles from './AccountCreated.module.css'
import accCato from '../Home/HomeImages/Accountcato.png'
const AccountCreated = () => {
  return (
    <>
      <div className={styles.created}>
<div className={styles.createdInner}>
  <div className={styles.textArea}>
    <div className={styles.heading}>¡Cuenta creada!</div>
    <div className={styles.bieno}>Bienvenido</div>
    <img src={accCato} className={styles.accCato} alt="" />
    <button className={styles.ira}>Ir a mi perfil</button>
  </div>
</div>
      </div>
    </>
  )
}

export default AccountCreated
