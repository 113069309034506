import "./App.css";
import AccountCreated from "./Components/AccountCreated/AccountCreated";
import Cart from "./Components/Cart/Cart";
import Deckbuilder from "./Components/Deckbuilder/Deckbuilder";
import Deckbuilder2 from "./Components/Deckbuilder2/Deckbuilder2";
import Errors1 from "./Components/Errors/Errors1";
import Errors2 from "./Components/Errors/Errors2";
import Faq from "./Components/Faq/Faq";
import Home from "./Components/Home/Home";
import WorkShop1 from "./Components/WorkShop1/WorkShop1";
import WorkShop2 from "./Components/WorkShop2/WorkShop2";
import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
import LoginCofirm from "./Components/Login/LoginConfirm";
import Myprofile from "./Components/Myprofile/Myprofile";
import Order from "./Components/Order/Order";
import CardView from "./Components/CardView/CardView";
import Deckbuilder3 from "./Components/Deckbuilder3/Deckbuilder3";
import Myorders from "./Components/Myorders/Myorders";
import Register from "./Components/Register/Register";
import SecretLair from "./Components/SecretLair/SecretLair";
import PreviewLair from "./Components/PreviewLair/PreviewLair";
import SecretLair2 from "./Components/SecretLair2/SecretLair2";
import VerticalSlider from "./Components/VerticalSlider/VerticalSlider";
import RecoveryEmail from "./Components/RecoveryEmail/RecoveryEmail";
import Search from "./Components/Search/Search";
import Cart2 from "./Components/Cart2/Cart2";
import TableDateAdminVehicle from "./Components/SecretLairCart/SecretLairCart";
import SecretLairCart from "./Components/SecretLairCart/SecretLairCart";
import DeliveryInformation from "./Components/DeliveryInformation/DeliveryInformation";
import Agregardireccion from "./Components/Agregar/Agregardireccion";
import Addresses from "./Components/Adresses/Addresses";
import OrderAdmin from "./Components/OrderAdmin/OrderAdmin";
import OrderDetail from "./Components/OrderDetail/OrderDetail";
import Hojear from "./Components/Hojear/Hojear";
import Tuscartas from "./Components/Tuscartas/Tuscartas";
import AdminLogin from "./Components/AdminLogin/AdminLogin";
import AdminHome from "./Components/AdminHome/AdminHome";
import AdminBulk from "./Components/AdminBulk/AdminBulk";
import AdminDivisa from "./Components/AdminDivisa/AdminDivisa";
import AdminCreditos from "./Components/AdminCreditos/AdminCreditos";
import AdminUpload from "./Components/AdminUpload.jsx/AdminUpload";
import AdminCartaUpload from "./Components/AdminCartaUpload/AdminCartaUpload";
import AdminPermisos from "./Components/AdminPermisos/AdminPermisos";
import AdminRetiro from "./Components/AdminRetiro/AdminRetiro";
import AdminSolicitudes from "./Components/AdminSolicitudes/AdminSolicitudes";
import AdminSolicitudesSL from "./Components/AdminSolicitudesSL/AdminSolicitudesSL";
import Approve from "./Components/Approve";
import DeliveryInformationSl from "./Components/DeliveryInformation/DeliveryInformationSl";
import OrderDetailSL from "./Components/OrderDetail/OrderDetailSL";
import MyorderDetail from "./Components/Myorders/MyorderDetail";
import MyorderDetailSl from "./Components/Myorders/MyorderDetailSl";
import { useEffect } from "react";
import ResetPassword from "./Components/Login/ResetPassword";

function App() {
  useEffect(() => {
    // Check if the current URL contains 'cartas.catlotus.cl'
    if (window.location.hostname === "cartas.catlotus.cl") {
      // Redirect to 'www.catlotus.cl'
      window.location.replace("https://www.catlotus.cl");
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/Hojear" element={<Hojear />} />{" "}
        <Route path="/deckbuilder3" element={<Deckbuilder3 />} />{" "}
        <Route path="/search" element={<Search />} />
        <Route path="/WorkShop1" element={<WorkShop1 />} />
        <Route path="/workShop2" element={<WorkShop2 />} />
        <Route path="/accountCreated" element={<AccountCreated />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart2" element={<Cart2 />} />
        <Route path="/deckbuilder" element={<Deckbuilder />} />
        <Route path="/deckbuilder2" element={<Deckbuilder2 />} />
        <Route path="/errors1" element={<Errors1 />} />
        <Route path="/errors2" element={<Errors2 />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/cardview/:set/:nombre/:cn/:part" element={<CardView />} />
        <Route path="/login" element={<Login />} />
        <Route path="/loginCofirm" element={<LoginCofirm />} />
        <Route path="/myorders" element={<Myorders />} />
        <Route path="/myordersDetail" element={<MyorderDetail />} />
        <Route path="/myordersDetailSl" element={<MyorderDetailSl />} />
        <Route path="/myprofile" element={<Myprofile />} />
        <Route path="/order" element={<Order />} />
        <Route path="/recoveryemail" element={<RecoveryEmail />} />
        <Route path="/register" element={<Register />} />
        <Route path="/secretliarr" element={<SecretLair />} />
        <Route path="/secretliar/:id" element={<SecretLair2 />} />
        <Route path="/secretliar" element={<PreviewLair />} />
        <Route path="/secretLaircart" element={<SecretLairCart />} />
        <Route path="/DeliveryInformation" element={<DeliveryInformation />} />
        <Route path="/reset/:id" element={<ResetPassword />} />
        <Route
          path="/DeliveryInformation_sl"
          element={<DeliveryInformationSl />}
        />
        <Route path="/Agregardireccion" element={<Agregardireccion />} />
        <Route path="/Addresses" element={<Addresses />} />
        <Route path="/OrderAdmin" element={<OrderAdmin />} />
        <Route path="/OrderDetail/:id" element={<OrderDetail />} />
        <Route path="/OrderDetail" element={<OrderDetail />} />
        <Route path="/OrderDetailsl" element={<OrderDetailSL />} />
        <Route path="/Tuscartas" element={<Tuscartas />} />
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/AdminHome" element={<AdminHome />} />
        <Route path="/AdminBulk" element={<AdminBulk />} />
        <Route path="/AdminDivisa" element={<AdminDivisa />} />
        <Route path="/AdminCreditos" element={<AdminCreditos />} />
        <Route path="/AdminUpload" element={<AdminUpload />} />
        <Route path="/AdminCartaUpload" element={<AdminCartaUpload />} />
        <Route path="/AdminPermisos" element={<AdminPermisos />} />
        <Route path="/AdminRetiro" element={<AdminRetiro />} />
        <Route path="/AdminSolicitudesSL" element={<AdminSolicitudesSL />} />
        <Route path="/AdminSolicitudes" element={<AdminSolicitudes />} />
        <Route path="/confirmar_cuenta/:id" element={<Approve />} />
        <Route path="/checkout" element={<Approve />} />
      </Routes>

      {/* <Home />  */}
      {/* <AccountCreated /> */}
      {/* <Cart/> */}
      {/* <Cart2/> */}
      {/* <WorkShop1 /> */}
      {/* <WorkShop2 /> */}
      {/* <Deckbuilder /> */}
      {/* <Deckbuilder2 /> */}
      {/* <Deckbuilder3/> */}
      {/* <Errors1 /> 
      <Errors2 />  
      <Faq /> */}
      {/* <CardView/>  */}
      {/* <Login />
      <LoginCofirm /> */}
      {/* <Myorders/>  */}
      {/* <Myprofile /> */}
      {/* <Order /> */}
      {/* <RecoveryEmail />
      <Register /> */}
      {/* <Search /> */}
      {/* <SecretLair/> */}
      {/* <PreviewLair /> */}
      {/* <SecretLair2 /> */}
      {/* <SecretLairCart/> */}

      {/* <SecretLair/> */}
      {/* <Deckbuilder3/>  */}

      {/* <DeliveryInformation/>
      <Agregardireccion/> */}
      {/* <Addresses/> */}
      {/* <OrderAdmin/> */}
      {/* <OrderDetail/> */}
      {/* <Hojear/> */}

      {/* <AdminLogin/>
     <AdminHome/>
     <AdminBulk/> */}
      {/* <AdminDivisa/>
     <AdminCreditos/> */}
      {/* <AdminUpload /> */}
      {/* <AdminCartaUpload/> */}
      {/* <AdminPermisos/> */}
      {/* <AdminRetiro /> */}
      {/* <AdminSolicitudes/> */}
      {/* <AdminSolicitudesSL/> */}
    </>
  );
}

export default App;
