import React from 'react'
import Footer from '../Footer/Footer'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './Faq.module.css'

const Faq = () => {
    return (
        <>
        <CatlotusNav/>
        <LowerNavbar/>
        <div className={styles.faq}>
            <div className={styles.hedaing}>¿Cómo comprar?</div>
            <div className={styles.headingDetail}>Para comprar simplemente tienes que buscar las cartas que quieres, con su nombre en inglés, y agregarlas a tu carro. Si no sabes el nombre en ingles, puedes buscarlo en español en <a href="www.scryfall.com" className={styles.underli}>www.scryfall.com</a> 
                 Una vez tengas listo tu carro, selecciona comprar y elige retiro o envío, completando la información de envío para el despacho. Asegúrate de ingresar correctamente la información por que se usará para el contacto y envío.
                Recibirás un correo con el detalle de la compra y las instrucciones para la transferencia. Asegúrate de enviar el comprobante a ventas@catlotus.cl</div>
                <div className={styles.pregHeading}>FAQ’s / Preguntas frecuentes</div>
                <div className={styles.estan}>¿Dónde están ubicados?</div>
                <div className={styles.somoss}>Somos solo tienda online sin ubicación para atender a publico.</div>
                <div className={styles.estan}>¿Cuál es el horario de atención?</div>
                <div className={styles.somoss}>Atendemos de lunes a viernes de 10:00 a 17:00.</div>
                <div className={styles.estan}>¿Bajo qué sistemas entregan?</div>
                <div className={styles.somoss}>Hacemos envios a todo Chile y tenemos retiros en la tienda Piedra Bruja cerca de metro Manuel Montt</div>
                <div className={styles.estan}>¿Cómo funciona el sistema de retiro?</div>
                <div className={styles.somoss}>Una vez que tu pedido está listo para retiro recibirás un correo con un c6digo de 3 digitos que debes presentar en la tienda Piedra Bruja. En el correo tambien esta la direction y horario de retiro. Cualquier persona puede hater retiro del pedido con el número de pedido y el c6digo de confirmación</div>
                <div className={styles.estan}>¿Mediante qué sistema despachan?</div>
                <div className={styles.somoss}>Dentro de la Región Metropolitana usamos un repartidor privado que entrega normalmente el dia hábil siguiente después que llega el correo que el pedido esta listo para envío. Fuera de la Región Metropolitana usamos Chilexpress con despacho dia hábil siguiente</div>
                <div className={styles.estan}>¿Pueden hacer envíos por pagar?</div>
                <div className={styles.somoss}>No. Solo se hacen envíos pagados.</div>

                <div className={styles.estan}>¿Pueden enviar a direcciones particulares o solo sucursales?</div>
                <div className={styles.somoss}>Se pueden hacer envíos tanto a direcciones particulares como sucursales. Sin embargo, nuestra recomendación es enviar a una sucursal de ser posible, ya que Chilexpess tiende a cometer menos errores de ser así.</div>
                <div className={styles.estan}>¿Qué ocurre si Chilexpress pierde mi pedido?</div>
                <div className={styles.somoss}>Primero tomamos contacto con Chilexpress para intenter localizar el envío. Si finalmente Chilexpress lo da por perdido, reembolsamos el costo del envío y sus contenidos.</div>
                <div className={styles.estan}>¿Cuál es el mínimo para hacer un pedido?</div>
                <div className={styles.somoss}>El mínimo es $15.000.</div>
                <div className={styles.estan}>¿Las cartas que están en la página están disponibles para compra inmediata?</div>
                <div className={styles.somoss}>Sí. Todas las cartas que están en el inventario las tenemos acá, y se envían en hasta 2 días hábiles después de confirmar el pago.</div>
                <div className={styles.estan}>¿Se pueden encargar cartas que no estén en la lista?</div>
                <div className={styles.somoss}>No. Solo trabajamos con las cartas que tenemos disponibles.</div>
                <div className={styles.estan}>Agregué una carta a mi carro, pero luego aparece como no disponible. ¿Qué ocurrió?</div>
                <div className={styles.somoss}>Esto significa que alguien más hizo la compra. El sistema verifica que todas las cartas del carro esten disponibles al momento de efectuar la compra antes de proceder.</div>
                <div className={styles.estan}>Tengo listo mi carro pero no puedo seleccionar el botón de Finalizar Compra. ¿Por qué es eso?</div>
                <div className={styles.somoss}>Eso significa que el monto está bajo del mínimo permitido o que alguna carta ya no está disponible.</div>
                <div className={styles.estan}>¿Qué sistemas de pago aceptan?</div>
                <div className={styles.somoss}>Transferencia bancaria, Mach y Webpay.</div>
        </div>
        <Footer/>
        </>
    )
}

export default Faq
