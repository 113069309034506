import React, { useState } from "react";
import styles from "./AdminLogin.module.css";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { serverInstance } from "../../axiosInstance";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { currentUserData } from "../../Redux/adminReducer";
const AdminLogin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [admin, setAdmin] = useState({ email: "", password: "" });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (admin.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (admin.password == "")
      return Swal.fire("Enter a valid password", "", "error");

    try {
      const data = await serverInstance.post("/admin/login", admin);
      console.log(data, "dataaa");
      let decoded = jwt_decode(data?.data.token);
      decoded["rol"] = JSON.parse(decoded.rol);
      console.log(decoded);
      localStorage.setItem("Admintoken", data.data.token);
      dispatch(currentUserData(decoded));
      navigate("/AdminHome", { replace: true });
      // Swal.fire("Login successfully", "", "success");
    } catch (err) {
      console.log(err);
      Swal.fire(
        err.response.data.message || "Something went wrong!",
        "",
        "error"
      );
    }
  };
  return (
    <>
      <div className={styles.loginAdmin}>
        <div className={styles.loginBox}>
          <div className={styles.heading}>Login</div>
          <div>
            <div className={styles.mailTitlt}>Email</div>
            <input
              type="email"
              className={styles.emailinput}
              value={admin.email}
              onChange={(e) => {
                setAdmin({ ...admin, email: e.target.value });
              }}
            />
          </div>
          <div>
            <div className={styles.mailTitlt}>Password</div>
            <input
              type="password"
              className={styles.emailinput}
              value={admin.password}
              onChange={(e) => {
                setAdmin({ ...admin, password: e.target.value });
              }}
            />
          </div>
          <button className={styles.submitt} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
