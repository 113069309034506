import React from "react";
import styles from "./AdminSideBar.module.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminSideBar = () => {
  const currentUser = useSelector((state) => state?.AdminReducer?.currentUser);

  console.log(currentUser.rol);
  return (
    <div>
      {["md"].map((expand) => (
        <Navbar key={expand} expand={expand} className={styles.sideNav}>
          <Container fluid className={styles.textTop}>
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className={styles.leftHave}
            >
              <span className={styles.leftHave1}>filters</span>{" "}
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              className={styles.blueBack}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <span className={styles.leftHave1}>filters</span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className={styles.abArea}>
                  <Link to="/" className={styles.tabOne}>
                    Home
                  </Link>
                  {currentUser.rol[4] == 1 ? (
                    <Link to="/AdminBulk" className={styles.tabOne}>
                      Bulk
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[1] == 1 ? (
                    <Link to="/AdminSolicitudes" className={styles.tabOne}>
                      Solicitudes
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[8] == 1 ? (
                    <Link to="/AdminRetiro" className={styles.tabOne}>
                      Retiro
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[2] == 1 ? (
                    <Link to="/AdminSolicitudesSL" className={styles.tabOne}>
                      Solicitudes SL
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[3] == 1 ? (
                    <Link to="/AdminDivisa" className={styles.tabOne}>
                      Divisa
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[5] == 1 ? (
                    <Link to="/AdminCreditos" className={styles.tabOne}>
                      Creditos
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[7] == 1 ? (
                    <Link to="/AdminUpload" className={styles.tabOne}>
                      Secret Lair Upload
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[6] == 1 ? (
                    <Link to="/AdminCartaUpload" className={styles.tabOne}>
                      Carta Upload
                    </Link>
                  ) : (
                    ""
                  )}
                  {currentUser.rol[0] == 1 ? (
                    <Link to="/AdminPermisos" className={styles.tabOne}>
                      Permisos
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link to="/AdminLogin" className={styles.tabOne}>
                    Logout
                  </Link>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
};

export default AdminSideBar;
