import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./Myorders.module.css";
import whiteKann from "../Images/whiteKann.png";
import whiteKann1 from "../Images/whiteKann1.png";
import CatlotusNav from "../Navbar/CatlotusNav";
import Footer from "../Footer/Footer";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import dato5 from "../Images/dato5.png";
import { Link } from "react-router-dom";
import dato1 from "../Images/dato1.png";
import dato2 from "../Images/dato2.png";
import { useSelector } from "react-redux";
import { serverInstance } from "../../axiosInstance";

const vehicleData = [
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
];
const vehicleData1 = [
  {
    ID: "2912",
    Monto: "$21,500",
    Fecha: "2021-05-29",
    Número: "0",
    Estado: "Rechazada",
  },
];

const Myorders = () => {
  const estados_cartas = [
    "Pendiente",
    "Confirmada",
    "Empacada",
    "Enviada",
    "Rechazada",
  ];
  var estados_sl = [
    "Pendiente",
    "Confirmada",
    "En camino a Chile",
    "Recepcionada en Chile",
    "Enviada",
    "Rechazada",
  ];
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  useEffect(() => {
    serverInstance
      .get(`/user/compras/${userInfo?.iduser}`)
      .then((res) => {
        console.log(res);
        setFirst(res.data.result1);
        setSecond(res.data.result2);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  }, []);

  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.Micuenta}>
        <div className={styles.yellowArea}>
          <div className={styles.heading}>Mi Cuenta</div>
          <div className={styles.datosArea}>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato1} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis datos personales</div>
              </div>
            </Link>
            <Link to="/myorders">
              <div className={styles.datmis}>
                <img src={dato2} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis compras</div>
              </div>
            </Link>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato5} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mi Libreta de direcciones</div>
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.cardArea}>
          <div className={styles.inerCardArea}>
            <div className={styles.title}>Cartas</div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tdd">
                    <TableCell align="right">
                      <div className={styles.stockDesi}>ID</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Monto</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Fecha de compra</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>
                        Número de seguimiento
                      </div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Estado</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Detalle</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {first?.map((row, index) => (
                    <TableRow
                      key={row?.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row?.ordencompra_id}</TableCell>
                      <TableCell align="right">{row?.monto}</TableCell>
                      <TableCell align="right">{row?.FechaOrden}</TableCell>
                      <TableCell align="right">
                        {row?.numero_seguimiento}
                      </TableCell>
                      <TableCell align="right">
                        {estados_cartas[Number(row?.estado_c)]}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={"/myordersDetail"}
                          state={{
                            id: row?.ordencompra_id,
                          }}
                        >
                          {" "}
                          <button className={styles.detail}>Detalle</button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className={styles.inerCardArea}>
            <div className={styles.title}>Secret Lair</div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tdd">
                    <TableCell align="right">
                      <div className={styles.stockDesi}>ID</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Monto</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Fecha de compra</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>
                        Número de seguimiento
                      </div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Estado</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Detalle</div>{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {second?.map((row, index) => (
                    <TableRow
                      key={row?.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row?.ordencompra_id}</TableCell>
                      <TableCell align="right">{row?.monto}</TableCell>
                      <TableCell align="right">{row?.FechaOrden}</TableCell>
                      <TableCell align="right">
                        {row?.numero_seguimiento}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {estados_sl[Number(row?.estado_c)]}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={"/myordersDetailSl"}
                          state={{
                            id: row?.ordencompra_id,
                          }}
                        >
                          {" "}
                          <button className={styles.detail}>Detalle</button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Myorders;
