import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { adminSlice } from "./Redux/adminReducer";
import { userSlice } from "./Redux/userReducer";

const adminPersistConfig = {
  key: "AdminReducer",
  version: 1,
  storage,
  whitelist: [
    "cartItems",
    "currentUser",
    "secretItems",
    "active",
    "creditoo",
    "editUser",
  ],
};

const userPersistConfig = {
  key: "userReducer",
  version: 1,
  storage,
};

const persistedAdminReducer = persistReducer(
  adminPersistConfig,
  adminSlice.reducer
);
const persistedUserReducer = persistReducer(
  userPersistConfig,
  userSlice.reducer
);

const syncMiddleware = createStateSyncMiddleware({
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
});

const store = configureStore({
  reducer: {
    AdminReducer: persistedAdminReducer,
    userReducer: persistedUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(syncMiddleware),
});

// Initialize message listener to sync state across tabs/windows
initMessageListener(store);

export const persistor = persistStore(store);
window.addEventListener("storage", (event) => {
  console.log("Storage event detected:", event.key);
  if (event.key === "persist:root") {
    console.log("persist:AdminReducer", "persist:AdminReducer");
    persistor.persist();
    persistor.flush();
  }
});

export default store;
