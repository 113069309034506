import React from 'react'
import styles from './Errors.module.css'
import fiImag from '../Images/builderImage.png'

const Errors2 = () => {
  return (
    <div className={styles.errordesign}>
      <div className={styles.error1}>
        <div className={styles.textArea}>
          <div className={styles.errorHeading}>¡Lo sentimos!</div>
          <div className={styles.errorDetail2}>El nombre ingresado no existe</div>
          <div className={styles.hemos}>Hemos buscado por todos lados y no lo encontramos. Inténtalo con otro nombre</div>
          <div className={styles.hemos1}>Recuerda que todos los nombres se deben buscar en inglés</div>
        </div>
        <img src={fiImag} className={styles.fiImag} alt="" />
      </div>
    </div>
  )
}

export default Errors2
