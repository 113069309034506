import React from "react";
import styles from "./Lower.module.css";
import comflo1 from "../Home/HomeImages/Gatobanner.png";
import basket from "../Home/HomeImages/basket.png";
import lair from "../Home/HomeImages/SecretLiar.png";
import { Link } from "react-router-dom";

const LowerNavbar = () => {
  return (
    <>
      <div className={styles.secra}>
        <div className={styles.secretArea}>
          {/* <img src={comflo1} className={styles.comflonav} alt="" /> */}
          <div className={styles.como}>
            <Link to="/" className={styles.compra}>
              <img src={comflo1} className={styles.comflonav} alt="" />{" "}
            </Link>
            <Link to="/Tuscartas" className={`${styles.compra} ${styles.mg}`}>
              Compramos tus cartas
            </Link>
          </div>
          <Link to="/faq" className={styles.compra}>
            FAQ’s
          </Link>
          <Link to="/Hojear" className={styles.compra}>
            Hojear cartas
          </Link>
          <Link to="/secretliar" className="dfg1">
            <img src={lair} className={styles.lair} alt="" />
          </Link>
          <Link to="/deckbuilder3" className={styles.compra}>
            Deckbuilder
          </Link>
          <Link to="/search" className={styles.compraworkShop}>
            Cartas
          </Link>
          <Link to="/cart2">
            <img src={basket} className={styles.comobasket} alt="" />
          </Link>
        </div>{" "}
        <Link to="/secretliar" className="dfg">
          <img src={lair} className={styles.lair} alt="" />
        </Link>
      </div>
    </>
  );
};

export default LowerNavbar;
