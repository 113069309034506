import React, { useState } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import styles from "./AdminBulk.module.css";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";

const AdminBulk = () => {
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    isFoil: "0",
    isSigned: false,
    lang: "eng",
    condicion: "0",
    image: null,
  });
  const [validationResults, setValidationResults] = useState([]);
  const validateFileContents = (fileContents) => {
    const lines = fileContents.split("\n");
    const results = [];

    // Define the regular expression pattern for each line
    const pattern =
      /^\d+ [A-Za-z,.''\-\s]+(?: \[[A-Z]+\])?(?: \[[A-Z0-9]+\])?$/;

    lines.forEach((line, lineNumber) => {
      if (!pattern.test(line?.trim())) {
        results.push(
          `Line ${lineNumber + 1} is not in the desired format: ${line}`
        );
      }
    });

    setValidationResults(results);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["text/plain"];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setState({ ...state, image: event.target.files[0] });
      const reader = new FileReader();
      reader.onload = function (event) {
        const fileContents = event.target.result;
        validateFileContents(fileContents);
      };
      reader.readAsText(selectedFile);
    } else {
      setState({ ...state, image: event.target.files[0] });
      setFile(null);
      alert("Please select a text file.");
    }
  };

  const handleSubmit = async () => {
    if (
      state.isFoil === "" ||
      state.lang === "" ||
      state.condicion === "" ||
      state.image === null
    ) {
      return Swal.fire("Provide all the fields", "", "error");
    } else {
      let data;
      data = new FormData();
      data.append("isFoil", state.isFoil);
      data.append("isSigned", state.isSigned);
      data.append("idioma", state.lang);
      data.append("condicion", state.condicion);
      data.append("image", state.image);

      await serverInstance
        .post("/cart/bulk", data)
        .then((res) => {
          console.log(res.data);
          setState({
            isFoil: "0",
            isSigned: false,
            lang: "eng",
            condicion: "0",
            image: null,
          });
          Swal.fire("You have added bulk items successfully", "", "success");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(err?.response?.data?.error, "", "error");
        });
    }
  };

  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            <div className={styles.heading}>Bulk upload</div>
            <div className={styles.user1}>
              <div className={styles.userHeading}>Escoja un idioma:</div>
              <div className={styles.selectStyle}>
                <Form.Select
                  aria-label="Default select example"
                  className={styles.selectStyle}
                  value={state.lang}
                  onChange={(e) => {
                    console.log(e.target.value, "e");
                    setState({ ...state, lang: e.target.value });
                  }}
                >
                  <option className={styles.optionsStyl} value="eng">
                    Inglés
                  </option>
                  <option className={styles.optionsStyl} value="esp">
                    Español
                  </option>
                  <option className={styles.optionsStyl} value="chi">
                    Chino
                  </option>
                  <option className={styles.optionsStyl} value="fre">
                    Francés
                  </option>
                  <option className={styles.optionsStyl} value="jpn">
                    Japonés
                  </option>
                  <option className={styles.optionsStyl} value="ger">
                    Alemán
                  </option>
                  <option className={styles.optionsStyl} value="ita">
                    Italiano
                  </option>
                  <option className={styles.optionsStyl} value="rus">
                    Ruso
                  </option>
                  <option className={styles.optionsStyl} value="kor">
                    Coreano
                  </option>
                  <option className={styles.optionsStyl} value="por">
                    Portugués
                  </option>
                </Form.Select>
              </div>
            </div>
            <div className={styles.user1} style={{ marginTop: "30px" }}>
              <div className={styles.userHeading}>
                Escoja la condición de las cartas:
              </div>
              <div className={styles.selectStyle}>
                <Form.Select
                  aria-label="Default select example"
                  className={styles.selectStyle1}
                  value={state.condicion}
                  onChange={(e) => {
                    console.log(e.target.value, "e");
                    setState({ ...state, condicion: e.target.value });
                  }}
                >
                  <option className={styles.optionsStyl} value="0">
                    NM
                  </option>
                  <option className={styles.optionsStyl} value="1">
                    SP
                  </option>
                  <option className={styles.optionsStyl} value="2">
                    MP
                  </option>
                  <option className={styles.optionsStyl} value="3">
                    HP
                  </option>
                </Form.Select>
              </div>
            </div>
            <div className={styles.signedArea}>
              <div className={styles.signedHeading}>Signed</div>
              <input
                type="checkbox"
                className={styles.singedInput}
                value={state.isSigned}
                onChange={(e) => {
                  console.log(e.target.checked, "e");
                  setState({ ...state, isSigned: e.target.value });
                }}
              />
            </div>
            <div className={styles.fileArea}>
              <div className={styles.fileHeading}>Foil</div>
              <div className={styles.selectStyle}>
                <Form.Select
                  aria-label="Default select example"
                  className={styles.selectStyle}
                  value={state.isFoil}
                  onChange={(e) => {
                    console.log(e.target.value, "e");
                    setState({ ...state, isFoil: e.target.value });
                  }}
                >
                  <option className={styles.optionsStyl} value="0">
                    No
                  </option>
                  <option className={styles.optionsStyl} value="1">
                    Foil
                  </option>
                  <option className={styles.optionsStyl} value="2">
                    Etched
                  </option>
                  <option className={styles.optionsStyl} value="3">
                    Oversized
                  </option>
                  <option className={styles.optionsStyl} value="4">
                    Oversized foil
                  </option>
                  <option className={styles.optionsStyl} value="5">
                    Gilded foil
                  </option>
                  <option className={styles.optionsStyl} value="6">
                    Texturized foil
                  </option>
                  <option className={styles.optionsStyl} value="7">
                    Surge foil
                  </option>
                  <option className={styles.optionsStyl} value="8">
                    Galaxy foil
                  </option>
                  <option className={styles.optionsStyl} value="9">
                    Oil slick foil
                  </option>
                  <option className={styles.optionsStyl} value="10">
                    Step-and-Compleat
                  </option>
                  <option className={styles.optionsStyl} value="11">
                    Halo
                  </option>
                  <option className={styles.optionsStyl} value="12">
                    Confetti foil
                  </option>
                  <option className={styles.optionsStyl} value="13">
                    Double Rainbow
                  </option>
                </Form.Select>
              </div>
              <input
                type="file"
                accept=".txt"
                className={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>

            <button className={styles.submitt} onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBulk;
