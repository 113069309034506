import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './Workshop1.module.css'
import './WorkShop1.css'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import coloImage from '../Images/color1.png'
import coloImage2 from '../Images/color2.png'
import coloImage3 from '../Images/color3.png'
import coloImage4 from '../Images/color4.png'
import coloImage5 from '../Images/color5.png'
import coloImage6 from '../Images/color6.png'
import lian1 from '../Images/lian.png'
import lian2 from '../Images/lian2.png'
import lian3 from '../Images/lian3.png'
import lian4 from '../Images/lian4.png'



let data = [
    {
        img: lian1,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian2,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian3,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian4,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian1,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian2,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian3,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian4,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian1,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian2,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian3,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian4,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian1,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian2,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian3,
        text: "Abnomination of Llanowar",
    },
    {
        img: lian4,
        text: "Abnomination of Llanowar",
    },


]


const WorkShop1 = () => {

    const [Index, setIndex] = useState();



    const cardClicked = (index) => {

        setIndex(index);

    }
    return (
        <>
            <div className={styles.workshop}>
                <CatlotusNav />
                <LowerNavbar />
                <div className={styles.padd}>
                    <div className={styles.workshop1}>
                        <div className={styles.commanderArea}>
                            <div className={styles.commanheading}>Comander Workshop</div>
                            <input className={styles.commanInput} type="text" />
                            <div className={styles.colo}>
                                <div className={styles.colorHeading}>Color</div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage} className={styles.coloImage} alt="" />
                                </div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage2} className={styles.coloImage} alt="" />
                                </div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage3} className={styles.coloImage} alt="" />
                                </div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage4} className={styles.coloImage} alt="" />
                                </div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage5} className={styles.coloImage} alt="" />
                                </div>
                            </div>
                            <div className={styles.colo}>
                                <div className={styles.colorHeading}>Color</div>
                                <div className={styles.selectioArea}>
                                    <input type="checkbox" className={styles.colorInput} />
                                    <img src={coloImage6} className={styles.coloImage} alt="" />
                                </div>
                            </div>
                            <button className={styles.aplicar}>Aplicar</button>
                        </div>
                        <div className={styles.aquiArea}>
                            <div className={styles.aquiText}>Aqui podrás armar tu nuevo comandante, mejorar el que tienes o cambiar la manera de armarlo. Selecciona el comandante que prefieres, búscalo con el nombre o filtro de colores, para ver las diferentes opciones para armarlo.</div>
                            <div className={styles.cardArea}>
                                {data.map((item, index) => (
                                    <div className={styles.card1}
                                        onClick={() => cardClicked(index)}
                                    >
                                        <div className={styles.textCrad}>{item.text}</div>
                                        <img src={item.img} className={styles.cardImage} alt="" />
                                        <div className={Index === index ? "termArea1" : "termArea"}>Buscar terms</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default WorkShop1
