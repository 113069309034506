import React, { useState } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import styles from "./AdminCreditos.module.css";
import Swal from "sweetalert2";
import { serverInstance } from "../../axiosInstance";

const AdminCreditos = () => {
  const [data, setData] = useState({ email: "", credito: 0 });
  const handleClick = (e) => {
    serverInstance
      .post("secret/cambioCredito", { data })
      .then((res) => {
        console.log(res);
        Swal.fire("Added successfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Something went wrong", "", "error");
      });
  };
  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            <div className={styles.heading}>Divisas actuales</div>
            {/* <div className={styles.heading}>Dolar: 820</div> */}
            <div className={styles.inputArea}>
              <div className={styles.putTlt}>Usuario (correo) </div>
              <input
                type="email"
                className={styles.numInput}
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </div>
            <div className={styles.inputArea} style={{ marginTop: "10px" }}>
              <div className={styles.putTlt}>Monto: </div>
              <input
                type="number"
                className={styles.numInput}
                value={data.credito}
                onChange={(e) => {
                  setData({ ...data, credito: parseInt(e.target.value) });
                }}
              />
            </div>
            <button className={styles.subButton} onClick={handleClick}>
              {" "}
              Enviar cambios{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreditos;
