import React from 'react'
import styles from './Errors.module.css'
import fiImag from '../Images/Dolp.png'

const Errors1 = () => {
  return (
    <div className={styles.errordesign}>
      <div className={styles.error1}>
        <div className={styles.textArea}>
          <div className={styles.errorHeading}>¡Lo sentimos!</div>
          <div className={styles.errorDetail}>No hay stock de la carta buscada</div>
        </div>
        <img src={fiImag} className={styles.fiImag} alt="" />
      </div>
    </div>
  )
}

export default Errors1
