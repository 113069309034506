import React, { useState, useEffect } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import styles from "./AdminDivisa.module.css";
import { useSelector } from "react-redux";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";

const AdminDivisa = () => {
  const user = useSelector((state) => state?.AdminReducer?.active);
  const [input, setInput] = useState(0);
  const [pre, setPre] = useState(0);
  const getDoller = () => {
    serverInstance
      .get("secret/divisa")
      .then((res) => {
        console.log(res.data, "resss");
        setInput(res.data.div[0]);
        setPre(res.data.div[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDoller();
  }, [user]);

  const handleClick = (e) => {
    serverInstance
      .post("secret/cambio", { dolar: input })
      .then((res) => {
        console.log(res);
        getDoller();
        Swal.fire("Added successfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Something went wrong", "", "error");
      });
  };
  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            <div className={styles.heading}>Divisas actuales</div>
            <div className={styles.heading}>Dolar: {pre}</div>
            <div className={styles.inputArea}>
              <div className={styles.putTlt}>Cambiar valor del dolar: </div>
              <input
                type="number"
                value={input}
                className={styles.numInput}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
            </div>
            <button className={styles.subButton} onClick={handleClick}>
              {" "}
              Enviar cambios{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDivisa;
