import React from "react";
import styles from "./Login.module.css";
import logincat from "../Images/logincat1.png";
import CatlotusNav from "../Navbar/CatlotusNav";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import { useState } from "react";
import { serverInstance } from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const LoginCofirm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  return (
    <>
      {" "}
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.outer} style={{ marginBottom: "40px" }}>
        <div className={styles.confirm}>
          <div className={styles.confirmHeading}>Recuperar contraseña</div>
          <div className={styles.detailTe}>
            Ingresa la cuenta de correo electrónico con el que creaste la
            cuenta. Te enviaremos en enlace por emali para que puedas
            reestablecer tu contraseña
          </div>
          <input
            type="email"
            placeholder="Correo"
            className={styles.coreee}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className={styles.ImageArea}>
            <button
              className={styles.Hind}
              onClick={(e) => {
                e.preventDefault();
                serverInstance
                  .post("/user/forgot-password", { correo: email })
                  .then((response) => {
                    console.log(
                      "Link de recuperación enviado:",
                      response.data
                    );
                    Swal.fire(
                      "Link de recuperación enviado:",
                      "",
                      "success"
                    );
                    navigate("/");
                  })
                  .catch((error) => {
                    console.error(
                      "Error al enviar el link:",
                      error.message
                    );
                    Swal.fire("Algo salió mal", "", "error");
                  });
              }}
            >
              Confirmar
            </button>
            <img src={logincat} className={styles.catcat} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginCofirm;
