import React, { useEffect, useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./AdminRetiro.module.css";
import Footer from "../Footer/Footer";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";

const AdminRetiro = () => {
  const [five, setFive] = useState([]);
  const [inputValues, setInputValues] = useState({});

  // Handle input change for each row
  const handleInputChange = (e, id) => {
    setInputValues({
      ...inputValues,
      [id]: e.target.value, // Update the input value for the specific row
    });
  };
  const handleConfirm = (id) => {
    const code = inputValues[id]; // Assuming you have stored input values in state
    if (code) {
      // Call the PUT API to confirm the code
      serverInstance
        .put(`/user/solicitudes/reterio/${id}`, { code }) // Pass the row ID and code as payload
        .then((res) => {
          console.log(`Confirmed row ID ${id}:`, res.data.message);

          // Show success message with Swal
          Swal.fire({
            title: "Success!",
            text: "Confirmation successful. Status updated.",
            icon: "success",
            confirmButtonText: "OK",
          });
Five()
          // Optionally refresh the data or update the UI after confirmation
        })
        .catch((err) => {
          console.error(
            `Error confirming row ID ${id}:`,
            err.response.data.message
          );

          // Show error message with Swal
          Swal.fire({
            title: "Error!",
            text: err.response.data.message || "Failed to confirm the code.",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        });
    } else {
      Swal.fire(`Please enter a code for row ID ${id}`, "", "error");
    }
  };

  const Five = () => {
    serverInstance
      .get("/user/solicitudes/reterio")
      .then((res) => {
        setFive(res?.data?.resultado);
        console.log(res);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };
  useEffect(() => {
    Five();
  }, []);

  return (
    <>
      <div>
        <CatlotusNav />
        <LowerNavbar />
        <div className={styles.margLower}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tdd">
                  {/* <TableCell align="right"><div className={styles.topRow}> Cantidad</div></TableCell> */}
                  {/* <TableCell align="right"><div className={styles.topRow}> Carta</div></TableCell> */}
                  {/* <TableCell align="right"><div className={styles.topRow}> Set</div><TableCell> */}

                  <TableCell align="right">
                    <div className={styles.topRow}> Numero pedido</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className={styles.topRow}>Codigo</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {five?.map((row, index) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{row?.id}</TableCell>
                    <TableCell align="right">
                      <div className={styles.cartaArea}>
                        <input
                          type="number"
                          value={inputValues[row?.id] || ""}
                          onChange={(e) => handleInputChange(e, row?.id)} // Handle input change
                        />
                        <button
                          className={styles.confi}
                          onClick={() => handleConfirm(row?.id)} // Handle confirmation
                        >
                          Confirmar
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AdminRetiro;
