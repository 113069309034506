import React from 'react'
import styles from "./RecoveryEmail.module.css"
import cat1 from '../Images/cat1.png'
import cat2 from '../Images/cat2.png'
import cat3 from '../Images/cat3.png'
import cat4 from '../Images/cat4.png'

const RecoveryEmail = () => {
  return (
    <div className={styles.recover}>
        <div className={styles.outerRecover}>
            <div className={styles.recoveryHeading}>Correo enviado</div>
            <div className={styles.recoverdetail}>Te hemos mandado un correo electrónico con un enlace para reestablacer la contraseña ¡corre a buscarlo!</div>
            <div className={styles.catsArea}>
                <img src={cat1} className={styles.cat1} alt="" />
                <img src={cat2} className={styles.cat2} alt="" />
                <img src={cat3} className={styles.cat3} alt="" />
                <img src={cat4} className={styles.cat4} alt="" />
            </div>
        </div>
      
    </div>
  )
}

export default RecoveryEmail
