import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./Navbar.module.css";
import { HiSearch } from "react-icons/hi";
import "./navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, setSearchC } from "../../Redux/userReducer";
import { serverInstance } from "../../axiosInstance";
import Autosuggest from "react-autosuggest"; // Import Autosuggest
import { useEffect } from "react";
import Tog from "../Images/ico.jpeg";
import basket from "../Home/HomeImages/basket.png";
import { BsCart4 } from "react-icons/bs";
import Swal from "sweetalert2";
function CatlotusNav() {
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (pathname.pathname !== "/search") {
      dispatch(setSearchC(""));
      console.log("remove");
    }
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(setSearchC(search));
      const encodedSuggestionValue = encodeURIComponent(search).replace(
        /[!'()*]/g,
        (c) => "%" + c?.charCodeAt(0)?.toString(16)
      );
      navigate(`/search?searchTerm=${encodedSuggestionValue}`, {
        replace: true,
      });
      setSearch("");
    }
  };
  const handleAutocomplete = (value) => {
    serverInstance
      .post("/search/completar", { id: value })
      .then((res) => {
        const suggestionsArray = res.data.data; // Modify this based on your API response structure
        console.log(res.data.data, "resdata");
        setSuggestions(suggestionsArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const autosuggestInputProps = {
    placeholder: "Busca tus cartas aqui...",
    value: search || "",
    onChange: (event, { newValue, method }) => {
      if (method !== 'down' && method !== 'up') {
        setSearch(newValue);  // Only update the input value if not using arrow keys
        handleAutocomplete(newValue);
      }
    },
    onKeyDown: handleEnterKeyPress,
  };
  // onKeyDown: handleEnterKeyPress,
  // Render suggestion
  const renderSuggestion = (suggestion) => (
    <div className="suggestion-container">
      <div className="suggestion">{suggestion}</div>
    </div>
  );
  const onSuggestionSelected = (event, { suggestionValue }) => {
    // Here, you can navigate to the specific page based on the selected suggestion
    // For example, you can navigate to a page with the card details using React Router

    // Example:
    console.log(suggestionValue);

    dispatch(setSearchC(suggestionValue));
    const encodedSuggestionValue = encodeURIComponent(suggestionValue)?.replace(
      /[!'()*]/g,
      (c) => "%" + c?.charCodeAt(0).toString(16)
    );
    navigate(`/search?searchTerm=${encodedSuggestionValue}`, { replace: true });
    setSearch("");
  };
  return (
    <>
      <div className={styles.backColo}>
        {["lg"].map((expand) => (
          <Navbar key={expand} expand={expand} className={styles.mainNav}>
            <Container fluid className={`${styles.centermain} `}>
              <div className={styles.fiftyArea}>
                {/* <Navbar.Brand href="#" className={styles.titl} >Cat Lotus</Navbar.Brand> */}
                <Navbar.Toggle
                  className={styles.togle}
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                >
                  <img src={Tog} className="topNavTog" />{" "}
                </Navbar.Toggle>

                <div className="topNavTogRight">
                  {" "}
                  <Link to="/cart2">
                    {" "}
                    <BsCart4 className={styles.comobasket} />{" "}
                  </Link>
                  {/* <img src={basket}  alt="" /> */}
                </div>

                <Navbar.Offcanvas
                  className={styles.stillOff}
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <Link to="/"> Cat Lotus</Link>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-start flex-grow-1 pe-3">
                      {/* <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link> */}

                      <div className={styles.loginARE1}>
                        <div className={styles.login1}>
                          {userInfo ? (
                            <Link to="/myprofile">Mi cuenta </Link>
                          ) : (
                            <Link to="/register">Registrarse </Link>
                          )}
                        </div>
                        <div className={styles.verticalAlign1}></div>
                        {userInfo ? (
                          <div
                            className={styles.signuptext1}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              dispatch(setCurrentUser(null));
                              localStorage.removeItem("userToken");
                              Swal.fire("logout successfully!");
                            }}
                          >
                            Cerrar sesión
                          </div>
                        ) : (
                          <Link to="/login">
                            <div className={styles.signuptext1}>
                              Iniciar Sesion
                            </div>
                          </Link>
                        )}
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
                <div className={styles.searcharaea}>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={() => { }}
                    onSuggestionsClearRequested={() => { }}
                    getSuggestionValue={(suggestion) => suggestion} // Modify this based on your API response structure
                    renderSuggestion={renderSuggestion}
                    inputProps={autosuggestInputProps}
                    onSuggestionSelected={onSuggestionSelected}
                  />
                  <HiSearch
                    className={styles.searchIcon}
                    onClick={() => {
                      dispatch(setSearchC(search));
                      const encodedSuggestionValue = encodeURIComponent(
                        search
                      ).replace(
                        /[!'()*]/g,
                        (c) => "%" + c.charCodeAt(0).toString(16)
                      );
                      navigate(`/search?searchTerm=${encodedSuggestionValue}`);
                      setSearch("");
                    }}
                  />
                </div>
                <div className={styles.loginARE}>
                  <div className={styles.login}>
                    {userInfo ? (
                      <Link to="/myprofile">Mi cuenta </Link>
                    ) : (
                      <Link to="/register">Registrarse </Link>
                    )}
                  </div>
                  <div className={styles.verticalAlign}></div>
                  {userInfo ? (
                    <div
                      className={styles.signuptext}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setCurrentUser(null));
                        localStorage.removeItem("userToken");
                        Swal.fire("logout successfully!");
                      }}
                    >
                      logout
                    </div>
                  ) : (
                    <Link to="/login">
                      <div className={styles.signuptext}>Iniciar Sesion</div>
                    </Link>
                  )}
                </div>
              </div>
            </Container>
          </Navbar>
        ))}
      </div>
    </>
  );
}

export default CatlotusNav;
