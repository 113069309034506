import React, { useState } from 'react'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './Agregar.module.css'
import Select from 'react-select'
import Footer from '../Footer/Footer'



const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]



const Agregardireccion = () => {


    const [radioSelect, setRadioSelect] = useState(1)
    const radioHandle = () => {
        console.log("radioHandle")
        setRadioSelect(1)
    }
    const radioHandle1 = () => {
        console.log("radioHandle")
        setRadioSelect(2)
    }




    return (
        <>
            <CatlotusNav />
            <LowerNavbar />
            <div className={styles.agregar}>
                <div className={styles.formMian}>
                    <div className={styles.maxArea}>
                        <div className={styles.titlePara}>Datos para envío</div>
                        <div className={styles.selectionArea}>
                            <div className={styles.decompra}>Método de compra</div>
                            <div className={styles.seclection1}>
                                <input type="radio" className={styles.checkInput} name="rad1"
                                    defaultChecked
                                    onChange={radioHandle}
                                />
                                <div className={styles.sucral}>Sucursal de Chilexpress</div>
                            </div>
                            <div className={styles.seclection1}>
                                <input type="radio" className={styles.checkInput} name="rad1"
                                    onChange={radioHandle1}
                                />
                                <div className={styles.sucral}>Direcciōn particular</div>
                            </div>
                        </div>
                        {radioSelect === 1 && <div className={styles.changeArea}>
                            <div className={styles.debas}>Debes poner el nombre da la sucrsal por como la llama chilespress. Por favor busca la sucursal que quieres en este link: <span style={{ color: "#5FC6FF" }}></span> Chilexexpress</div>
                            <div className={styles.namesDiv} style={{ marginTop: "21px", marginLeft: "20px" }}>
                                <div className={styles.nameHeading}>Ingrese el nombre de la sucursal</div>
                                <input type="text" placeholder='ej. PICK UP DON PEPE' className={styles.firstName} />
                            </div>
                        </div>}
                        {radioSelect === 2 && <div className={styles.changeArea2}>
                            <div className={styles.callArea}>
                                <div className={styles.callyaChart}>
                                    <div className={styles.chalTilt}>Calle</div>
                                    <input type="text" placeholder='Bello Horizonte' className={styles.horizonta} />
                                </div>
                                <div className={styles.callyaChart}>
                                    <div className={styles.chalTilt}>Numero</div>
                                    <input type="text" placeholder='809' className={styles.horizonta} />
                                </div>
                                <div className={styles.callyaChart}>
                                    <div className={styles.chalTilt}>Departamento</div>
                                    <input type="text" placeholder='809' className={styles.horizonta} />
                                </div>
                            </div>
                            <div className={styles.callyaChart}>
                                <div className={styles.chalTilt}>Torre/Block/Villa</div>
                                <input type="text" className={styles.horizonta} />
                            </div>
                        </div>}
                        <div className={styles.regionArea}>
                            <div className={styles.regionTitleArea}>
                                <div className={styles.regionTitle}>Regiōn</div>
                                <Select
                                    className={styles.selectPut}
                                    placeholder="Seleccione region"
                                    options={options} />
                            </div>
                            <div className={styles.regionTitleArea}>
                                <div className={styles.regionTitle}>Comuna</div>
                                <Select
                                    className={styles.selectPut}
                                    placeholder="Seleccione Comuna"
                                    options={options} />
                            </div>
                        </div>
                        <div className={styles.deentrega}>Instucciones de entrega</div>
                        <textarea className={styles.textLoweer}></textarea>
                        <button className={styles.complitarButton}>Completar compra</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Agregardireccion
