import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./Myorders.module.css";
import whiteKann from "../Images/whiteKann.png";
import whiteKann1 from "../Images/whiteKann1.png";
import CatlotusNav from "../Navbar/CatlotusNav";
import Footer from "../Footer/Footer";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import dato5 from "../Images/dato5.png";
import { Link, useLocation } from "react-router-dom";
import dato1 from "../Images/dato1.png";
import dato2 from "../Images/dato2.png";
import { useSelector } from "react-redux";
import { serverInstance } from "../../axiosInstance";

const MyorderDetail = () => {
  const location = useLocation();
  const { id } = location?.state;
  const estados_cartas = [
    "Pendiente",
    "Confirmada",
    "Empacada",
    "Enviada",
    "Rechazada",
  ];
  var estados_sl = [
    "Pendiente",
    "Confirmada",
    "En camino a Chile",
    "Recepcionada en Chile",
    "Enviada",
    "Rechazada",
  ];
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  useEffect(() => {
    serverInstance
      .get(`/user/detalle_compra/${id}/${userInfo?.iduser}`)
      .then((res) => {
        console.log(res);
        setFirst(res.data.resultado);
      })
      .catch((err) => {
        console.log("in error", err);
      });
  }, []);

  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.Micuenta}>
        <div className={styles.yellowArea}>
          <div className={styles.heading}>Mi Cuenta</div>
          <div className={styles.datosArea}>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato1} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis datos personales</div>
              </div>
            </Link>
            <Link to="/myorders">
              <div className={styles.datmis}>
                <img src={dato2} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis compras</div>
              </div>
            </Link>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato5} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mi Libreta de direcciones</div>
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.cardArea}>
          <div className={styles.inerCardArea}>
            <div className={styles.title}>Cartas</div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tdd">
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Cantidad</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Precio individual </div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Precio total</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Carta</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Edición</div>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Numero de Carta</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Idioma</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Condicion</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.stockDesi}>Foil/Firmada</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {first?.map((row, index) => (
                    <TableRow
                      key={row?.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row?.cantidad}</TableCell>
                      <TableCell align="right">
                        {row?.precio_carta?.toLocaleString("es-CL")}
                      </TableCell>
                      <TableCell align="right">
                        {row?.monto?.toLocaleString("es-CL")}
                      </TableCell>
                      <TableCell align="right">{row?.nombre}</TableCell>
                      <TableCell align="right">
                        {row?.estado_c?.set?.toUpperCase()}
                      </TableCell>
                      <TableCell align="right">
                        {row?.collector_number}
                      </TableCell>
                      <TableCell align="right">
                        {row?.idioma?.toUpperCase()}
                      </TableCell>
                      <TableCell align="right">
                        {row?.estado === 0 ? null : (
                          <span>
                            {row?.estado === 1 && "SP"}
                            {row?.estado === 2 && "MP"}
                            {row?.estado === 3 && "HP"}
                            {row?.estado === 4 && "DMG"}
                            {row?.estado === 5 && "ALT"}
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row?.foil === 1 && !row?.signed
                          ? "Foil"
                          : row?.foil === 2 && !row?.signed
                          ? "Etched"
                          : row?.foil === 3 && !row?.signed
                          ? "Oversized"
                          : row?.foil === 4 && !row?.signed
                          ? "Oversized Foil"
                          : row?.foil === 5 && !row?.signed
                          ? "Gilded"
                          : row?.foil === 6 && !row?.signed
                          ? "Texturized"
                          : row?.foil === 7 && !row?.signed
                          ? "Surge"
                          : row?.foil === 8 && !row?.signed
                          ? "Galaxy"
                          : row?.foil === 9 && !row?.signed
                          ? "Oil Slick"
                          : row?.foil === 1 && row?.signed
                          ? "Foil/Signed"
                          : row?.foil === 2 && row?.signed
                          ? "Etched/Signed"
                          : row?.foil === 3 && row?.signed
                          ? "Oversized/Signed"
                          : row?.foil === 4 && row?.signed
                          ? "Oversized Foil/Signed"
                          : row?.foil === 5 && row?.signed
                          ? "Gilded/Signed"
                          : row?.foil === 6 && row?.signed
                          ? "Texturized/Signed"
                          : row?.foil === 7 && row?.signed
                          ? "Surge/Signed"
                          : row?.foil === 8 && row?.signed
                          ? "Galaxy/Signed"
                          : row?.foil === 9 && row?.signed
                          ? "Oil Slick/Signed"
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyorderDetail;
