import React, { useEffect, useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import dato1 from "../Images/dato1.png";
import dato2 from "../Images/dato2.png";
import dato3 from "../Images/dato3.png";
import dato4 from "../Images/dato4.png";
import dato5 from "../Images/dato5.png";
import styles from "./Myprofile.module.css";
import Footer from "../Footer/Footer";
import profileCat from "../Images/profileCat.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { serverInstance } from "../../axiosInstance";
import { setCurrentUser } from "../../Redux/userReducer";

const Myprofile = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.userReducer?.activeUser);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateB: "",
    phone: "",
    rut: ""
  });
  const [pass, setPass] = useState({
    old: "",
    password: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [credito, setCredito] = useState(0);
  const [showPhone, setShowPhone] = useState(false);
  let name, value;

  const handleInput = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };
  useEffect(() => {
    const dateOfBirth = new Date(
      userInfo?.fecha_nacimiento
    ).toLocaleDateString();
    setUser({
      email: userInfo?.email,
      firstName: userInfo?.nombre,
      lastName: userInfo?.apellido,
      dateB: dateOfBirth,
      phone: userInfo?.telefono,
      rut: userInfo?.rut
    });
  }, []);
  useEffect(() => {
    if (userInfo) {
      serverInstance
        .post("/user/getCredit", {
          id: userInfo?.iduser,
        })
        .then((data) => {
          console.log(data, data.credito);
          setCredito(data?.data.credito);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (pass.old == "" || pass.confirmPassword == "" || pass.password == "") {
      Swal.fire("Fill all the fields", "", "error");
    } else if (pass.password !== pass.confirmPassword) {
      Swal.fire("password and confirm password should be same", "", "error");
    } else {
      serverInstance
        .post("/user/change_password", {
          oldpass: pass.old,
          newpass: pass.password,
          userID: userInfo.iduser,
        })
        .then((res) => {
          Swal.fire(res.data.message, "", "success");
          if (res.status == 200) {
          } else {
          }
        })
        .catch((err) => {
          console.log("in error", err);
          Swal.fire(err.response.data.message, "", "error");
        });
    }
  };
  const handlePhone = (e) => {
    e.preventDefault();
    if (user.phone == "") {
      Swal.fire("Enter phone number", "", "error");
    } else {
      serverInstance
        .post("/user/telefono", {
          phone: user?.phone,
          userID: userInfo.iduser,
        })
        .then((res) => {
          let userI = { ...userInfo };
          userI.phone = user?.phone;
          dispatch(setCurrentUser(userI));
          Swal.fire(res.data.message, "", "success");
        })
        .catch((err) => {
          console.log("in error", err);
          Swal.fire(err.response.data.message, "", "error");
        });
    }
  };
  return (
    <div className={styles.myProff}>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.profle}>
        <div className={styles.yellowArea}>
          <div className={styles.heading}>Mi Cuenta</div>
          <div className={styles.datosArea}>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato1} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis datos personales</div>
              </div>
            </Link>
            <Link to="/myorders">
              <div className={styles.datmis}>
                <img src={dato2} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mis compras</div>
              </div>
            </Link>
            <Link to="/myprofile">
              <div className={styles.datmis}>
                <img src={dato5} className={styles.dato1} alt="" />
                <div className={styles.misDato}>Mi Libreta de direcciones</div>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.inputsArea}>
          <div className={styles.imageArea}>
            <img src={profileCat} className={styles.profileCat} alt="" />
            <div className={styles.javies}>
              <input
                name="firstName"
                type="name"
                value={user.firstName}
                onChange={handleInput}
                placeholder="Ignacio Javier"
                className={styles.javieriNput}
                disabled={true}
              />
              <input
                name="lastName"
                type="name"
                value={user.lastName}
                onChange={handleInput}
                placeholder="Energici Sprovera"
                className={styles.second}
                disabled
              />
            </div>
          </div>
          <div className={styles.inputDtae}>
            <input
              name="email"
              type="email"
              value={user.email}
              placeholder="Ienergici@gmail.com"
              className={styles.emailInput}
              disabled
            />  <input
              name="rut"
              type="email"
              value={user.rut}
              placeholder="343456565"
              className={styles.dateput1}
              disabled
            />
          </div>
          <div className={styles.inputDtae}>
            <input
              name="dateB"
              type="text"
              value={user.dateB}
              readOnly
              className={styles.dateput}
            />
            <input
              name="phone"
              type="text"
              value={user.phone}
              onChange={handleInput}
              placeholder="+56997447617"
              className={styles.dateput1}
              disabled
            />
          </div>{" "}
          <div className="d-flex align-items-end">
            <div style={{ fontSize: "30px", fontWeight: "bold" }}>Credito</div>
            <input
              name="credito"
              value={`$${credito}`}
              className={styles.dateput1}
              disabled
            />
          </div>
          {!showPass && !showPhone && (
            <div className="d-flex">
              <div
                className={styles.ccbutton}
                onClick={() => {
                  setShowPass(true);
                  setShowPhone(false);
                }}
              >
                Cambiar contraseña{" "}
              </div>
              <div
                className={styles.ccbutton}
                onClick={() => {
                  setShowPass(false);
                  setShowPhone(true);
                }}
              >
                Cambiar teléfono{" "}
              </div>
            </div>
          )}
          {showPass && (
            <div>
              <div className={styles.hrmb}></div>
              <div className={styles.titulo}>Cambiar contraseña</div>
              <div>
                <input
                  type="password"
                  value={pass.old}
                  onChange={(e) => {
                    setPass({ ...pass, old: e.target.value });
                  }}
                  className={styles.formPass}
                />
                <div className={styles.lab}>Contraseña antigua</div>
              </div>
              <div className={styles.inputDtae}>
                <div>
                  <input
                    type="password"
                    value={pass.password}
                    onChange={(e) => {
                      setPass({ ...pass, password: e.target.value });
                    }}
                    className={styles.formPass}
                  />
                  <div>Contraseña nueva </div>
                </div>
                <div>
                  <input
                    type="password"
                    value={pass.confirmPassword}
                    onChange={(e) => {
                      setPass({ ...pass, confirmPassword: e.target.value });
                    }}
                    // onChange={handleInput}
                    className={styles.formPass}
                  />
                  <div>Contraseña nueva </div>
                </div>
              </div>
              <div className={styles.ccbutton} onClick={handleSubmit}>
                Cambiar contraseña{" "}
              </div>
              <div className={styles.hrmb}></div>
            </div>
          )}
          {showPhone && (
            <div>
              <div className={styles.hrmb}></div>
              <div className={styles.titulo}>Cambiar número de teléfono</div>
              <div>
                <input
                  type="text"
                  value={user.phone}
                  onChange={(e) => {
                    setUser({ ...user, phone: e.target.value });
                  }}
                  className={styles.formPass}
                />
                <div className={styles.lab}>Nuevo número de teléfono</div>
              </div>

              <div className={styles.ccbutton} onClick={handlePhone}>
                Cambiar número de teléfono
              </div>
              <div className={styles.hrmb}></div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Myprofile;
