import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./AdminPermisos.module.css";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setEditUser, setUserModel } from "../../Redux/adminReducer";

function MyVerticallyCenteredModal(props) {
  const dispatch = useDispatch();
  const editUser = useSelector((state) => state?.AdminReducer?.editUser);
  const active = useSelector((state) => state?.AdminReducer?.active);
  const [user, setUser] = useState({
    nombre: "",
    apellido: "",
    email: "",
    rol: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  useEffect(() => {
    if (editUser == null) {
      setUser({
        nombre: "",
        apellido: "",
        email: "",
        rol: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      });
    } else {
      setUser(editUser);
    }
  }, [editUser]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (user.nombre == "") return Swal.fire("Enter a nombre", "", "error");

    try {
      const data = await serverInstance.post("/admin/create", user);
      console.log(data, "dataaa");
      dispatch(setUserModel(false));
      props.setUsers(data?.data?.all);
      Swal.fire("User added successfully", "", "success");
    } catch (err) {
      console.log(err);
      Swal.fire(
        err.response.data.message || "Something went wrong!",
        "",
        "error"
      );
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (user.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (user.nombre == "") return Swal.fire("Enter a nombre", "", "error");

    try {
      const data = await serverInstance.patch("/admin/editUser", user);
      console.log(data?.data?.all, "dataaa");
      data && props.setUsers(data?.data?.all);
      dispatch(setEditUser(null));
      dispatch(setUserModel(false));
      Swal.fire("User Updated successfully", "", "success");
    } catch (err) {
      console.log(err);
      Swal.fire(
        err.response.data.message || "Something went wrong!",
        "",
        "error"
      );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Crear usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.numer}>
          <div className={styles.numTag}>Nombre: </div>
          <input
            type="text"
            className={styles.inputNumber}
            value={user.nombre}
            onChange={(e) => {
              setUser({ ...user, nombre: e.target.value });
            }}
          />
        </div>
        <div className={styles.numer}>
          <div className={styles.numTag}>Apellido: </div>
          <input
            type="text"
            className={styles.inputNumber}
            value={user.apellido}
            onChange={(e) => {
              setUser({ ...user, apellido: e.target.value });
            }}
          />
        </div>
        <div className={styles.numer}>
          <div className={styles.numTag}>Correo: </div>
          <input
            type="text"
            className={styles.inputNumber}
            value={user.email}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Control de usuarios :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[0] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[0] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Pedidos :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[1] == 0 ? false : true}
            onChange={(e) => {
              console.log(e.target.checked);
              let roll = [...user.rol];
              roll[1] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.checked);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Pedidos SL :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[2] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[2] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Divisa :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[3] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[3] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Bulk Upload :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[4] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[4] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Credito :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[5] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[5] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Carta Upload :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[6] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[6] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Secret Lair Upload :</div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[7] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[7] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
        <div className={styles.contr}>
          <div className={styles.numTag}>Entregas prescenciales : </div>
          <input
            type="checkbox"
            className={styles.inputcheck}
            checked={user.rol[8] == 0 ? false : true}
            onChange={(e) => {
              let roll = [...user.rol];
              roll[8] = e.target.checked ? 1 : 0;
              setUser({
                ...user,
                rol: roll,
              });
              console.log(roll, e.target.value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={editUser !== null ? handleUpdate : handleSubmit}>
          {editUser !== null ? "Update" : "Crear"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const AdminModal = ({ setUsers }) => {
  const dispatch = useDispatch();
  const active = useSelector((state) => state?.AdminReducer?.active);

  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    setModalShow(active);
  }, [active]);

  return (
    <>
      <Button
        className={styles.launchButton}
        onClick={() => {
          dispatch(setEditUser(null));
          dispatch(setUserModel(true));
        }}
      >
        Add Crear Usuario
      </Button>

      <MyVerticallyCenteredModal
        setUsers={setUsers}
        show={modalShow}
        onHide={() => {
          dispatch(setUserModel(false));
        }}
      />
    </>
  );
};

export default AdminModal;
