import React from "react";
import styles from "./Login.module.css";
import logincat from "../Images/logincat1.png";
import CatlotusNav from "../Navbar/CatlotusNav";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import { useState } from "react";
import { serverInstance } from "../../axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  console.log(pathname.split("/"));
  const [email, setEmail] = useState({ password: "", confirmPassword: "" });

  return (
    <>
      {" "}
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.outer} style={{ marginBottom: "40px" }}>
        <div className={styles.confirm} style={{ minHeight: "50vh" }}>
          <div className={styles.confirmHeading}>Cambio de contraseña</div>
          <div className="d-flex justify-content-between gap-3 w-100 px-5  ">
            {" "}
            <input
              type="password"
              placeholder="Nueva contraseña"
              className={styles.coreee}
              value={email.password}
              onChange={(e) => {
                setEmail({ ...email, password: e.target.value });
              }}
            />
            <input
              type="password"
              placeholder="Repita la contraseña"
              className={styles.coreee}
              value={email.confirmPassword}
              onChange={(e) => {
                setEmail({ ...email, confirmPassword: e.target.value });
              }}
            />
          </div>
          <div className={styles.ImageArea}>
            <button
              className={styles.Hind}
              onClick={(e) => {
                e.preventDefault();
                serverInstance
                  .post("/user/reset-password", {
                    newpass: email.password,
                    hash: pathname.split("/")[2],
                  })
                  .then((response) => {
                    console.log(
                      "Link enviado correctamente:",
                      response.data
                    );
                    Swal.fire("Clave cambiada correctamente:", "", "success");
                    navigate("/");
                  })
                  .catch((error) => {
                    console.error(
                      "Error al enviar el link:",
                      error.message
                    );
                    Swal.fire("Algo salió mal", "", "error");
                  });
              }}
            >
              Confirmar
            </button>
            <img src={logincat} className={styles.catcat} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
