import React, { useState } from 'react'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Footer from '../Footer/Footer';
import styles from './OrderAdmin.module.css'

const OrderAdmin = () => {

    const [tabchange, setTabchange] = useState(1)


    const tabHandle = () => {
        setTabchange(1)
    }
    const tabHandle1 = () => {
        setTabchange(2)
    }
    const tabHandle2 = () => {
        setTabchange(3)
    }
    const tabHandle3 = () => {
        setTabchange(4)
    }
    const tabHandle4 = () => {
        setTabchange(5)
    }

    const vehicleData = [
        {
            ID: "11980",
            Nombre: "Mauracio",
            Apellido: "Aboid",
            Email: "mauricio aboidf@gmail.com",
            Monto: "31.650",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12010",
            Nombre: "Alejandro",
            Apellido: "Canales",
            Email: "alcanalescrt@gmail.com",
            Monto: "104.450",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12015",
            Nombre: "Javier",
            Apellido: "Fredes Alejandro Gonzalez",
            Email: "jaatredes@gmail.com",
            Monto: "282.300",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12321",
            Nombre: "Tomás",
            Apellido: "Weibel",
            Email: "tomasweibel@gmail.com",
            Monto: "149.200",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12336",
            Nombre: "Francisco",
            Apellido: "Armijo",
            Email: "fra.armijo@gmail.com",
            Monto: "32.600",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12360 ",
            Nombre: "RODRIGO ",
            Apellido: "OLIVOS ",
            Email: "rodrigo patoolivos pavez@gmail.com",
            Monto: "21850",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12365",
            Nombre: "Vicente",
            Apellido: "Santibañez",
            Email: "psico vesm@gmail.com",
            Monto: "26.800",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12383",
            Nombre: "Javier Fredes",
            Apellido: "Alejandro Gonzalez",
            Email: "jaafredes@gmail.com",
            Monto: "139.550",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12432 ",
            Nombre: "Diego",
            Apellido: "Soto Tordecilla",
            Email: "enderlost54@gmail.com",
            Monto: "544.150",
            Credito: "0",
            Fecha: "2021-05-29",
        },
        {
            ID: "12451",
            Nombre: "Neil",
            Apellido: "Gilchrist",
            Email: "mauricio aboidf@gmail.com",
            Monto: "41.300",
            Credito: "0",
            Fecha: "2021-05-29",
        },



    ]



    return (
        <>
            <CatlotusNav />
            <LowerNavbar />
            <div className={styles.order}>
                <div className={styles.orderInner}>
                    <div className={styles.topButons}>
                        <button className={styles.secondbutton}
                            onClick={tabHandle}
                        >Compras pendientes</button>
                        <button className={styles.secondbutton}
                            onClick={tabHandle1}
                        >Compras confirmadas</button>
                        <button className={styles.secondbutton}
                            onClick={tabHandle2}
                        >Compras empacadas</button>
                        <button className={styles.secondbutton}
                            onClick={tabHandle3}
                        >Compras Completadas</button>
                        <button className={styles.secondbutton}
                            onClick={tabHandle4}
                        >Compras Canceladas</button>
                    </div>
                    <div className={styles.searchArea}>
                        <div className={styles.textSaecr}>Search Orders</div>
                        <input type="text" className={styles.serInput} />
                    </div>
                    <div className={styles.lowerTable}>
                        {tabchange === 1 && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right"><div className={styles.topRow}> ID</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Nombre</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Apellido</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Email</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Monto</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Credito</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Fecha de compra</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Confirmar</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Cancelar</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Dirección</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Detalle</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Notas</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell align="right"><div className={styles.tabData}>{row?.ID}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Nombre}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Apellido}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Email}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Monto}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Credito}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Fecha}</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Confirmar</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Cancelar</div></TableCell>
                                            <TableCell align="right"><div className={styles.confiblue}>Dirección</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Detalle</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Notas</div></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {tabchange === 2 && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right"><div className={styles.topRow}> ID</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Nombre</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Apellido</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Email</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Monto</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Credito</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Fecha de compra</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Empacar</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Direccion</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Detalles </div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Notas</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell align="right"><div className={styles.tabData}>{row?.ID}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Nombre}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Apellido}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Email}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Monto}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Credito}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Fecha}</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Empacar</div></TableCell>
                                            <TableCell align="right"><div className={styles.confiblue}>Dirección</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Detalles</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Notas</div></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {tabchange === 3 && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right"><div className={styles.topRow}> ID</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Nombre</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Apellido</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Email</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Monto</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Credito</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Fecha de compra</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Confirmar </div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Enviar</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Cancelar</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Dirección</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Detalle</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Notas</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell align="right"><div className={styles.tabData}>{row?.ID}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Nombre}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Apellido}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Email}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Monto}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Credito}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Fecha}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>Número de seguimuento</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Enviar</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Cancelar</div></TableCell>
                                            <TableCell align="right"><div className={styles.confiblue}>Dirección</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Detalle</div></TableCell>
                                            <TableCell align="right"><div className={styles.detl}>Notas</div></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {tabchange === 4 && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right"><div className={styles.topRow}> ID</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Nombre</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Apellido</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Email</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Monto</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Credito</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Fecha de compra</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Confirmar </div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Detalles </div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Notas</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell align="right"><div className={styles.tabData}>{row?.ID}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Nombre}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Apellido}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Email}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Monto}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Credito}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Fecha}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>Número de seguimuento</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Detalles </div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Notas</div></TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {tabchange === 5 && <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right"><div className={styles.topRow}> ID</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Nombre</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Apellido</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Email</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Monto</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Credito</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Fecha de compra</div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Detalles </div></TableCell>
                                        <TableCell align="right"><div className={styles.topRow}> Notas</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell align="right"><div className={styles.tabData}>{row?.ID}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Nombre}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Apellido}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Email}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Monto}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>${row?.Credito}</div></TableCell>
                                            <TableCell align="right"><div className={styles.tabData}>{row?.Fecha}</div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Detalles </div></TableCell>
                                            <TableCell align="right"><div className={styles.confi}>Notas</div></TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default OrderAdmin
