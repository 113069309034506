import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './WorkShop2.module.css'
import './Workshop2.css'
import lianCard from '../Images/lian.png'


let data = [
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Commandersa"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Partnersalena"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
    {
        text: "Default"
    },
]



const WorkShop2 = () => {

    const [Index, setIndex] = useState();



    const cardClicked = (index) => {

        setIndex(index);

    }




    return (
        <>
            <CatlotusNav />
            <LowerNavbar />
            <div className={styles.tamasOuter}>
                <div className={styles.workname}>
                    <div className={styles.temasRea}>
                        <div className={styles.laion}>Abmominaton of Llanowar</div>
                        <img src={lianCard} alt="" />
                    </div>
                    <div className={styles.defaArea}>
                        <div className={styles.temesRIght}>
                            <div className={styles.teeemHeading}>TEMAS</div>
                            <div className={styles.sonlos}>Estos son los arquetipos o formas de armar este comandante. Elige el que te interese y podrás ver todas las cartas disponibles del inventario en el deckbuilder para armar la configuración que más te guste</div>
                        </div>
                        <div className={styles.defaultArea}>
                            {data.map((item, index) => (
                                <div className={Index === index ? "cards2" : "cards1"} onClick={() => cardClicked(index)}>{item.text}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WorkShop2
