import React from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./Adresses.module.css";
import whiteKann from "../Images/dato1.png";
import whiteKann1 from "../Images/whiteKann1.png";
import dato5 from "../Images/dato5.png";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FaPen } from "react-icons/fa";
import Footer from "../Footer/Footer";
import "./Adress.css";

const Addresses = () => {
    const vehicleData = [
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet ",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
        {
            address:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur, diam sit amet luctus congue,",
        },
    ];

    return (
        <>
            <CatlotusNav />
            <LowerNavbar />
            <div className={styles.adrese}>
                <div className={styles.yelArea}>
                    <div className={styles.geading}>Mi Cuenta</div>
                    <div className={styles.cuenta}>
                        <div className={styles.micant}>
                            <img src={whiteKann} className={styles.whiteKann} alt="" />
                            <div className={styles.personal}>Mis datos personales</div>
                        </div>
                        <div className={styles.micant}>
                            <img src={whiteKann1} className={styles.whiteKanncenter} alt="" />
                            <div className={styles.personal}>Mis compras</div>
                        </div>
                        <div className={styles.micant}>
                            <img src={dato5} className={styles.whiteKannlast} alt="" />
                            <div className={styles.personal} style={{ marginLeft: "13px" }}>
                                Mi Libreta de direcciones
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightArea}>
                    <div className={styles.rightAreaInner}>
                        <div className={styles.cratashEad}>Cartas</div>
                        <button className={styles.addNew}>Add new address</button>
                        <TableContainer component={Paper} className="tablArea">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tdd">
                                        <TableCell align="right">
                                            <div className={styles.topRow}>Addresses</div>
                                        </TableCell>
                                        <TableCell align="right">
                                            <div className={styles.topRow}> Edit</div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicleData?.map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell align="right">
                                                <div className={styles.addressDetail}>
                                                    {row?.address}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className={styles.edits}>
                                                    <FaPen className={styles.penInne} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Addresses;
