import axios from "axios";
// export const BASE_URL =
//   process.env.NODE_ENV !== "development"
//     ? "https://thankful-top-coat-toad.cyclic.app"
//     : "http://localhost:6002";
export const BASE_URL = "https://catlotus.cl/api";
//export const BASE_URL = "http://localhost:6002";
export const serverInstance = axios.create({
  baseURL: `${BASE_URL}/`,
  // timeout : 3000,
});
