import React from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import builderImage from "../Images/builderImage.png";
import styles from "./Deckbuilder.module.css";
import Footer from "../Footer/Footer";

const Deckbuilder = (props) => {
  return (
    <>
      <div>
        <div className={styles.builderrr}>
          <div className={styles.builder}>
            <div className={styles.rightBuilder}>
              <div className={styles.imageArea}>
                <div className={styles.detailText}>
                  <div className={styles.iloHeading}>¡Lo sentimos!</div>
                  <div className={styles.losaNL}>
                    Los nombres de la lista no existen
                  </div>
                  <div className={styles.hemps}>
                    Hemps buscado por todos lados y no lo encontramos. Inténtalo
                    con otro nombre
                  </div>
                  <div className={styles.prpertiesArea}>
                    {props?.notFound.map((d) => {
                      return <div className={styles.twig}>{d}</div>;
                    })}
                  </div>
                </div>
                <div className={styles.imageArea2}>
                  <img
                    src={builderImage}
                    className={styles.builderImage}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deckbuilder;
