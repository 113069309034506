import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { CiEdit } from "react-icons/ci";
import { MdDeleteForever } from "react-icons/md";
import "./PermisosTable.css";
import { setEditUser, setUserModel } from "../../Redux/adminReducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { serverInstance } from "../../axiosInstance";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="right">
          <div className="topRow"> Nombre</div>
        </TableCell>
        <TableCell align="right">
          <div className="topRow"> Apellido</div>
        </TableCell>
        <TableCell align="right">
          <div className="topRow"> Correo</div>
        </TableCell>

        <TableCell align="right">
          <div className="topRow"> Options</div>
        </TableCell>
        <TableCell align="right">
          <div className="topRow"> Editar</div>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          User Management
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ users, setUsers }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = await serverInstance.delete(`/admin/${id}`);
          if (data) {
            const updatedUsers = users?.filter(
              (user) => user.id_administrador !== id
            );

            // Update the 'users' array with the filtered array
            setUsers(updatedUsers);
            Swal.fire("Deleted!", "User has been deleted.", "success");
          }
        } catch (err) {}
      }
    });
  };

  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {console.log(
                users?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              )}
              {users
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let rol = JSON.parse(row.rol);
                  console.log(index);
                  return (
                    <TableRow
                      // tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        className="shCenter"
                      >
                        {row.nombre}
                      </TableCell>
                      <TableCell align="center" className="shCenter">
                        {row.apellido}
                      </TableCell>
                      <TableCell align="center" className="shCenter">
                        {row.email}
                      </TableCell>
                      <TableCell align="center" className="shCenter">
                        {rol.map((d, ind) => {
                          return (
                            <>
                              <div>
                                {ind == 0 && d == 1
                                  ? "Control de usuarios"
                                  : ""}
                                {ind == 1 && d == 1 ? "Pedidos" : ""}
                                {ind == 2 && d == 1 ? "Pedidos SL" : ""}
                                {ind == 3 && d == 1 ? "Divisa" : ""}
                                {ind == 4 && d == 1 ? "Bulk Upload" : ""}
                                {ind == 5 && d == 1 ? "Credito" : ""}
                                {ind == 6 && d == 1 ? "Carta Upload" : ""}
                                {ind == 7 && d == 1 ? "Secret Lair Upload" : ""}
                                {ind == 8 && d == 1
                                  ? "Entregas prescenciales"
                                  : ""}
                              </div>
                            </>
                          );
                        })}
                      </TableCell>
                      <TableCell align="center" className="shCenter">
                        <div className="editArea">
                          <CiEdit
                            className="editTag"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let temp = { ...row };
                              temp["rol"] = JSON.parse(row?.rol);
                              dispatch(setUserModel(true));
                              dispatch(setEditUser(temp));
                            }}
                          />
                          <MdDeleteForever
                            onClick={() => handleDelete(row?.id_administrador)}
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            className="editTag"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
