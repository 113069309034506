import React from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import builderImage from "../Images/Dolp.png";
import styles from "./Deckbuilder2.module.css";
import Footer from "../Footer/Footer";

const Deckbuilder2 = (props) => {
  return (
    <>
      <div>
        <div className={styles.builderrr}>
          <div className={styles.builder}>
            <div className={styles.rightBuilder}>
              <div className={styles.imageArea}>
                <div className={styles.detailText}>
                  <div className={styles.iloHeading}>Carta sin stock</div>
                  <div className={styles.losaNL}>
                    No temos las siguientes cartas en intentario
                  </div>
                  <div className={styles.hemps}>
                    Prieba buscarla cuando publicamos que llegan cartas nuevas
                  </div>
                  <div className={styles.prpertiesArea}>
                    {props?.outStock?.map((d) => {
                      return <div className={styles.twig}>{d}</div>;
                    })}

                    {/* <div className={styles.twig}>Wanering Fumarole</div>
                    <div className={styles.twig}>Waderwine Hub</div>
                    <div className={styles.twig}>Wnderwine Prophets</div> */}
                  </div>
                </div>
                <div className={styles.imageArea2}>
                  <img
                    src={builderImage}
                    className={styles.builderImage}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deckbuilder2;
