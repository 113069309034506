import React from 'react'
import LowerNavbar from '../LowerNavbar/LowerNavbar'
import Footer from '../Footer/Footer'
import CatlotusNav from '../Navbar/CatlotusNav'
import styles from './SecretLair.module.css'
import lairImage from '../Images/lairImage.png'
import yellowLair from '../Images/yellowLair.png'
import blackLair from '../Images/blackLair.png'
import edi1 from '../Images/edi1.png'
import edi2 from '../Images/edi2.png'
import edi3 from '../Images/edi3.png'
import edi4 from '../Images/edi4.png'
import ant1 from '../Images/ant1.png'
import ant2 from '../Images/ant2.png'
import ant3 from '../Images/ant3.png'


let data = [
  {img:ant2},
  {img:ant2},
  {img:ant2},
  {img:ant2},
  {img:ant2},
  {img:ant2},
  {img:ant3},
  {img:ant3},
  {img:ant3},
  {img:ant3},

]

let data1 = [
  {
    imgg:edi1,
    title:"Secret Liar por Walking Dead",
    date:"Octubre 2020",
    desp:"Descripción breve del mazo y algunas características de esta."
  },
  {
    imgg:edi2,
    title:"Presentando: Zendikar Revisited",
    date:"Septiembre 2020",
    desp:"Destaca algunos de los grandes éxitos de Zendikar en el estilo artistico y el marco de Zendikar Rising Showcasel"
  },
  {
    imgg:edi3,
    title:"Happy Yargle Day!",
    date:"Octubre 2020",
    desp:"Es el día de yargle! Selle sus chimeneas y apague sus luces festivas porque Yargle vene a la ciodad..."
  },
  {
    imgg:edi4,
    title:"Every Dog Has Its Day - Foil",
    date:"Agosto 2020",
    desp:"¡Feliz Día Internacional del Perro 2020! Honramos a los chicos y chicas más bonitos que conocemos en este adorable evento con temática de perros con algunos de los favoritos de Magic."
  },
]


const SecretLair = () => {
  return (
    <div>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.seret}>
        <img src={lairImage} className={styles.lairImage} alt="" />
        <div className={styles.laiSec}>
          <img src={yellowLair} className={styles.yellowLair} alt="" />
        </div>
        <div className={styles.bien}>
          <div className={styles.bienText}>
            <div className={styles.lainText}>
              <div className={styles.line}></div>
              <div className={styles.lainHeading}>Bienvenido a Secret Liar</div>
              <div className={styles.line}></div>
            </div>
            <div className={styles.lorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices metus ut purus imperdiet, et placerat risus ultrices. Nullam mattis feugiat diam. Etiam fermentum, eros et aliquam posuere, leo nunc lobortis orci, et ultrices velit risus ac augue. Donec pharetra felis eget magna vehicula, a pretium ex condimentum. Fusce nec f</div>
          </div>
          <img src={blackLair} alt="" className={styles.blackLair} />
        </div>
        <div className={styles.bienText1}>
          <div className={styles.lainText}>
            <div className={styles.line}></div>
            <div className={styles.lainHeading1}>Ediciones anteriores</div>
            <div className={styles.line}></div>
          </div>
        </div>
        <div className={styles.cardsArea}>
        {data1.map((item,index) => (
          <div className={index%2==0?styles.card1:styles.card2}>
            <img src={item.imgg} className={styles.edi1} alt="" />
            <div className={styles.secreArea}>
              <div className={styles.por}>{item.title}</div>
              <div className={styles.twnty}>{item.date }</div>
              <div className={styles.desta}>{item.desp}</div>
               <div className={styles.fishArea}>
                <div className={styles.leftFish}>
                  <img src={ant1} className={styles.ant1} alt="" />
                  <div className={styles.fishBlack}>
                  {data.map((item) => (
                    <img src={item.img} className={styles.black} alt="" />
                    ))}
                  </div>
                </div>
                <button className={styles.infor}>+  Información</button>
               </div>
            </div>
          </div>
            ))}
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default SecretLair
