import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./VerticalSlider.css";

import { BASE_URL } from "../../axiosInstance";

function VerticalSlider(props) {
  const [pics, setPics] = useState([]);
  const { activeImage, setActiveImage } = props;

  useEffect(() => {
    setPics(props?.pics);
    // if (props?.pics?.length > 0) {
    //   setActiveImage(props.pics[0]);
    // }
  }, [props]);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
      // Update the activeImage based on the nextSlide index
      setActiveImage(pics[nextSlide]);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  return (
    <div className="vertical-slider-container">
      <Slider {...settings} className="slidMian">
        {pics?.map((item, index) => (
          <div key={index} className="mainSlid">
            <img
              src={
                BASE_URL +
                "/public" +
                "/secretlair/" +
                String(item?.id + item?.ext)
              }
              className="innerImage"
              alt=""
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default VerticalSlider;
