import React, { useEffect, useState } from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import styles from "./SecretLair2.module.css";
import Lair2 from "../Images/Lair2.png";
import Footer from "../Footer/Footer";
import VerticalSlider from "../VerticalSlider/VerticalSlider";
import PreviewLair1 from "../Images/PreviewLair1.png";
import { BASE_URL, serverInstance } from "../../axiosInstance";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSecretItem } from "../../Redux/adminReducer";
import Swal from "sweetalert2";
const SecretLair2 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const condi = location?.state?.condi;
  const secretItems = useSelector((state) => state?.AdminReducer?.secretItems);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState("");
  const [index1, setIndex] = useState(0);
  const [dat, setDat] = useState();
  const [p, setP] = useState([]);
  const [pics, setPics] = useState([]);
  const [values, setValues] = useState([]);
  const [timee, setTimess] = useState();
  const [activeImage, setActiveImage] = useState(null);
  const [banner, setBanner] = useState(null);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    tipo: 0,
    tipo_name: "",
    cantidad: 0,
    nombre: "",
    foto: "",
    precio: 0,
  });

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevCount) => prevCount - 1);
      setSelectedItem({
        ...selectedItem,
        cantidad: quantity - 1,
      });
    }
  };

  const handleIncrement = () => {
    if (quantity < Infinity) {
      setQuantity((prevCount) => prevCount + 1);
    }
    setSelectedItem({
      ...selectedItem,
      cantidad: quantity + 1,
    });
  };

  const hanlePrice = (item, i) => {
    console.log(
      "dddddd",
      id,
      i,
      item?.value,
      item?.name,
      quantity,
      dat?.values?.res1[0]?.nombre,
      activeImage?.id + activeImage?.ext,
      item?.value
    );
    setPrice(item?.value);
    setIndex(i);

    // Update the selected item
    setSelectedItem({
      id: id,
      tipo: i,
      tipoo: item?.value,
      tipo_name: item?.name,
      cantidad: quantity,
      nombre: dat?.values?.res1[0]?.nombre,
      foto: activeImage?.id + activeImage?.ext,
      precio: item?.value,
    });
  };
  useEffect(() => {
    serverInstance
      .get(`/secret/lair/${id}`)
      .then((res) => {
        console.log(res);
        setDat(res.data);
        setP(res.data?.values?.res1[0]?.desc?.split("\r\n"));
        setBanner(res.data?.banner?.link);
        setValues(res.data?.values?.res2);
        setPics(res.data?.values?.res4);
        setPrice(res.data?.values?.res2[0]?.value);

        if (res.data?.values?.res4?.length > 0) {
          setActiveImage(res.data?.values?.res4[0]);
        }
        setSelectedItem({
          id: id,
          tipo: 0,
          tipoo: res.data?.values?.res2[0]?.value,
          tipo_name: res.data?.values?.res2[0]?.name,
          cantidad: 1,
          nombre: res.data?.values?.res1[0]?.nombre,
          foto: res.data?.values?.res4[0]?.id + res.data?.values?.res4[0]?.ext,
          precio: res.data?.values?.res2[0]?.value,
        });
        console.log(res.data?.time);
        setTimess(
          new Date(
            res.data?.time[2],
            Number(res.data?.time[0]) - 1,
            res.data?.time[1],
            Number(res.data?.time[3]),
            Number(res.data?.time[4])
          )
        );
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 400) {
          Swal.fire("Secret Lair is not active.", "", "error");
        }
      });
  }, [id]);
  return (
    <div>
      <CatlotusNav />
      <LowerNavbar />
      <img
        src={banner}
        className={styles.preciew}
        alt=""
        style={{ marginTop: "-37px", width: "100%" }}
      />
      <div className={styles.cuultOUTER}>
        <div className={styles.cuult}>
          <div className={styles.imageArea}>
            <div className={styles.sliderArea}>
              <VerticalSlider
                pics={pics}
                activeImage={activeImage}
                setActiveImage={setActiveImage}
              />
              {activeImage && (
                <img
                  src={
                    BASE_URL +
                    "/public" +
                    "/secretlair/" +
                    String(activeImage?.id + activeImage?.ext)
                  }
                  className={styles.Lair2}
                  alt=""
                />
              )}
            </div>
            {dat && <div className={styles.conten}>
              <div className={styles.bantLine}>CONTENIDO:</div>
              {dat?.values?.res3?.map((d) => (
                <div
                  className={styles.bantLine}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {d?.nombre}
                </div>
              ))}
            </div>}
          </div>
          <div className={styles.buttonsArea}>
            <div className={styles.cultHead}>
              {dat?.values?.res1[0]?.nombre}
            </div>
            {/* timee < new Date(dat?.values?.res1[0]?.start */}
            {false ? (
              <>
                <div className={styles.dala}>
                  La preventa comienza el:{" "}
                  {dat?.values?.res1[0]?.start &&
                    String(new Date(dat?.values?.res1[0]?.start)?.getDate()) +
                      "/" +
                      String(
                        new Date(dat?.values?.res1[0]?.start)?.getMonth() + 1
                      ) +
                      "/" +
                      String(
                        new Date(dat?.values?.res1[0]?.start)?.getFullYear()
                      )}
                </div>
                <div className={styles.fecha}>
                  Fecha de llegada:{" "}
                  {dat?.values?.res1[0]?.estimada &&
                    String(
                      new Date(dat?.values?.res1[0]?.estimada)?.getDate()
                    ) +
                      "/" +
                      String(
                        new Date(dat?.values?.res1[0]?.estimada)?.getMonth() + 1
                      ) +
                      "/" +
                      String(
                        new Date(dat?.values?.res1[0]?.estimada)?.getFullYear()
                      )}
                  aproximadamente
                </div>
              </>
            ) : // timee < new Date(dat?.values?.res1[0]?.end
            true ? (
              <>
                <div className={styles.amOUNT}>
                  {price && "$"} {price?.toLocaleString("es-CL")}
                </div>
                {dat && condi !== "pre" ? (
                  <>
                    <div className={styles.una}>Elige una opción:</div>
                    <div className={styles.diffButtons}>
                      {values?.map(
                        (item, index) =>
                          parseInt(values[index]?.value) > 0 && (
                            <button
                              className={
                                index === index1 ? styles.abiet : styles.cerrad
                              }
                              onClick={() => hanlePrice(item, index)}
                            >
                              {item?.name}
                            </button>
                          )
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {dat && condi !== "pre" ? (
                  <div className={styles.preButtons}>
                    <div className={styles.numInputs}>
                      <div className={styles.minus} onClick={handleDecrement}>
                        -
                      </div>
                      <div className={styles.colorfullInput}>{quantity}</div>
                      <div className={styles.plus} onClick={handleIncrement}>
                        +
                      </div>
                    </div>

                    <button
                      className={styles.ahora}
                      disabled={condi === "pre" ? true : false}
                      onClick={(e) => {
                        const existingIndex = secretItems.findIndex(
                          (item) => item.id === selectedItem.id
                        );
                        console.log(
                          existingIndex,
                          "existingIndex",
                          selectedItem
                        );
                        if (existingIndex !== -1) {
                          // Replace the existing item
                          const updatedItems = [...secretItems];
                          updatedItems[existingIndex] = selectedItem;
                          dispatch(setSecretItem(updatedItems));
                        } else {
                          // Push the new item
                          const updatedItems = [...secretItems, selectedItem];
                          dispatch(setSecretItem(updatedItems));
                        }
                        Swal.fire({
                          html: `<div>${dat?.values?.res1[0]?.nombre} </div> <div>Added successfully into cart</div>`,
                          icon: "success",
                        });
                      }}
                    >
                      Pre-ordenar ahora
                    </button>
                  </div>
                ) : (
                  dat && "Preventa cerrada"
                )}
                <div className={styles.estaBundle}>
                  {p?.map((dd, ind) => {
                    return <div className={styles.bantLine}>{dd}</div>;
                  })}
                </div>{" "}
                {dat && condi !== "pre" ? (
                  <div className={styles.dala}>
                    Preventa abierta, termina el:{" "}
                    {dat?.values?.res1[0]?.end &&
                      String(new Date(dat?.values?.res1[0]?.end)?.getDate()) +
                        "/" +
                        String(
                          new Date(dat?.values?.res1[0]?.end)?.getMonth() + 1
                        ) +
                        "/" +
                        String(
                          new Date(dat?.values?.res1[0]?.end)?.getFullYear()
                        )}
                  </div>
                ) : (
                  ""
                )}
                {dat && (
                  <div className={styles.fecha}>
                    Fecha de llegada:{" "}
                    {dat?.values?.res1[0]?.estimada &&
                      String(
                        new Date(dat?.values?.res1[0]?.estimada)?.getDate()
                      ) +
                        "/" +
                        String(
                          new Date(dat?.values?.res1[0]?.estimada)?.getMonth() +
                            1
                        ) +
                        "/" +
                        String(
                          new Date(
                            dat?.values?.res1[0]?.estimada
                          )?.getFullYear()
                        )}
                    aproximadamente
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                <div className={styles.estaBundle}>
                  {p?.map((dd, ind) => {
                    return <div className={styles.bantLine}>{dd}</div>;
                  })}
                </div>{" "}
                {dat?.values?.res1[0]?.status && (
                  <StatusMessage status={dat?.values?.res1[0]?.status} />
                )}
                {dat && (
                  <div className={styles.fecha}>
                    Fecha de llegada:{" "}
                    {dat?.values?.res1[0]?.estimada &&
                      String(
                        new Date(dat?.values?.res1[0]?.estimada)?.getDate()
                      ) +
                        "/" +
                        String(
                          new Date(dat?.values?.res1[0]?.estimada)?.getMonth() +
                            1
                        ) +
                        "/" +
                        String(
                          new Date(
                            dat?.values?.res1[0]?.estimada
                          )?.getFullYear()
                        )}
                    aproximadamente
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
function StatusMessage({ status }) {
  let message = "";

  if (Number(status) === 1) {
    message = "Esperando envio por Wizards";
  } else if (Number(status) === 2) {
    message = "Esperando recepción USA";
  } else if (Number(status) === 3) {
    message = "Importado";
  } else if (Number(status) === 4) {
    message = "Listo para entregar";
  } else if (Number(status) === 5) {
    message = "Cerrado";
  } else {
    message = "Preventa cerrada";
  }

  return <h5 style={{ color: "#b9935a" }}>{message}</h5>;
}
export default SecretLair2;
