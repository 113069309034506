import React, { useState } from "react";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import styles from "./AdminCartaUpload.module.css";
import { serverInstance } from "../../axiosInstance";
import Swal from "sweetalert2";

const AdminCartaUpload = () => {
  const [data, setData] = useState({ id: "", foto: null });
  const handleClick = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("id", data.id);
    form.append("files", data.foto);
    serverInstance
      .post("secret/carta_upload", form)
      .then((res) => {
        console.log(res);
        Swal.fire("Added successfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Something went wrong", "", "error");
      });
  };
  return (
    <>
      <div className={styles.home}>
        <AdminSideBar />
        <div className={styles.logArea}>
          <div className={styles.loginBox}>
            <div className={styles.heading}>Sube imagen a una carta</div>
            <div className={styles.IdArea}>
              <div className={styles.idHead}>ID:</div>
              <input
                type="number"
                className={styles.idInput}
                value={data.id}
                onChange={(e) => {
                  setData({ ...data, id: parseInt(e.target.value) });
                }}
              />
            </div>
            <div className={styles.IdArea}>
              <div className={styles.idHead}>Foto:</div>
              <input
                type="file"
                onChange={(e) => {
                  setData({ ...data, foto: e.target.files[0] });
                }}
              />
            </div>
            <button className={styles.submitt} onClick={handleClick}>
              Upload Files
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCartaUpload;
