import React from "react";

const Empt = () => {
  return (
    <div
      style={{ width: "100%", textAlign: "center", height: "100%" }}
      className="d-flex mt-4 justify-content-center align-items-center flex-column"
    >
      <p>Con esta herramienta podras buscar distintos grupos de cartas segun</p>{" "}
      categorias preseleccionadas.
      <p>
        Mas vendidas: Una selección de las cartas mas vendidas de la tienda.
      </p>
      <p>Base commander: Una base para cualquier mazo commander.</p>
      <p>Tokens: Todas (o casi todas) las tokens disponibles.</p>
      <p>Más valiosas: Una selección de las cartas más caras de la tienda.</p>
      <p>Tierras promo: Las tierras promocionales disponibles</p>
      <p>Tierras nevadas: Las tierras nevadas disponibles</p>
      <p>
        Alpha / Beta / Unlimited: Todas las cartas de las primeras 3 ediciones
        de Magic
      </p>
    </div>
  );
};

export default Empt;
