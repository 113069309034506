import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  cartItems: [],
  secretItems: [],
  active: false,
  creditoo: false,
  editUser: null,
};

export const adminSlice = createSlice({
  name: "AdminReducer",
  initialState,
  reducers: {
    setCredit(state, action) {
      state.creditoo = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    currentUserData(state, action) {
      state.currentUser = action.payload;
    },
    setUserModel(state, action) {
      state.active = action.payload;
    },
    setEditUser(state, action) {
      state.editUser = action.payload;
    },
    setCartItem(state, action) {
      state.cartItems = action.payload;
    },
    setSecretItem(state, action) {
      state.secretItems = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setCurrentUser,
  setUserModel,
  currentUserData,
  setEditUser,
  setCartItem,
  setSecretItem,
  setCredit,
  resetState,
} = adminSlice.actions;

export default adminSlice.reducer;
