import React from "react";
import styles from "./Cart.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./Cart.css";
import bander1 from "../Images/bander1.png";

const Cart = () => {
  const vehicleData = [
    {
      Cantidad: "2",
      Carta1: "K’rrik, Son of Yawgmoth",
      Carta2: bander1,
      Set: "c19",
      Estado: "NM",
      Idioma: "eng",
      Foil: "",
      Stock: "4",
      Precio: "$ 11,300",
      Total: "$ 22,600",
    },
  ];

  return (
    <>
      <div className="cart">
        <div className={styles.cartInner}>
          <div className={styles.top}>
            <div className={styles.leftcarro}>
              Carro de compras de Cartas
            </div>
            <div className={styles.rightCarro}>
              Carro de compras de Secret Lair
            </div>
          </div>
          <div className={styles.margLower}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tdd">
                    {/* <TableCell align="right"><div className={styles.topRow}> Cantidad</div></TableCell> */}
                    {/* <TableCell align="right"><div className={styles.topRow}> Carta</div></TableCell> */}
                    {/* <TableCell align="right"><div className={styles.topRow}> Set</div><TableCell> */}

                    <TableCell align="right">
                      <div className={styles.topRow}> Cantidad</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Carta</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Set</div>
                    </TableCell>

                    <TableCell align="right">
                      <div className={styles.topRow}> Estado</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Idioma</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}>Tratamiento foil</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Stock</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Preci0</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className={styles.topRow}> Total</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicleData?.map((row, index) => (
                    <TableRow
                      key={row?.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">{row?.Cantidad}</TableCell>
                      <TableCell align="right">
                        <div className={styles.cartaArea}>
                          <div className={styles.cartAtext}>{row?.Carta1}</div>
                          <img
                            src={row?.Carta2}
                            className={styles.bander1}
                            alt=""
                          />
                        </div>
                      </TableCell>
                      <TableCell align="right">{row?.Set}</TableCell>
                      <TableCell align="right">
                        <div className={styles.num}>{row?.Estado}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.idio}>{row?.Idioma}</div>
                      </TableCell>
                      <TableCell align="right">{row?.Foil}</TableCell>
                      <TableCell align="right">{row?.Stock}</TableCell>
                      <TableCell align="right">{row?.Precio}</TableCell>
                      <TableCell align="right">{row?.Total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className={styles.pricArea}>
            <div className={styles.totalPargo}>
              Total a pagar: <span className={styles.dollar}>$22.600</span>
            </div>
            <div className={styles.dicountArea}>
              <div className={styles.radioArea}>
                <input type="radio" className={styles.radioInput} />{" "}
                <div className={styles.diucouynPrice}>Envio (+$4.000)</div>
              </div>
              <div className={styles.radioArea}>
                <input type="radio" className={styles.radioInput} />{" "}
                <div className={styles.diucouynPrice}>Retiro (+ $0)</div>
              </div>
            </div>
            <div className={styles.priceDetail}>
              Retiro en tienda Piedra Bruja en metro Manuel Montt
            </div>
            <button className={styles.comprara}>Comprar</button>
            <button className={styles.anadir}>Añadir</button>
          </div>
          <div className={styles.sunii}>
            Si tienes un pedido pendiente de pago, puedes agregarle más cartas
            haciendo un nuevo pedido{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;

{
  /* <table>
<tr className="tdd">
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
  <th>
    <div className={styles.topRow}> Cantidad</div>
  </th>
</tr>
{vehicleData?.map((row, index) => (
  <tr>
    <td>
      <div> {row?.Cantidad}</div>
    </td>
    <td>
    <div className={styles.cartaArea}>
            <div className={styles.cartAtext}>{row?.Carta1}</div>
            <img
              src={row?.Carta2}
              className={styles.bander1}
              alt=""
            />
          </div>
    </td>
    <td>
      <div> {row?.Set}</div>
    </td>
    <td>
      <div> <div className={styles.num}>{row?.Estado}</div></div>
    </td>
    <td>
    <div className={styles.idio}>{row?.Idioma}</div>
    </td>
    <td>
      <div> {row?.Cantidad}</div>
    </td>
    <td>
      <div> {row?.Cantidad}</div>
    </td>
    <td>
      <div> {row?.Cantidad}</div>
    </td>
    <td>
      <div> {row?.Cantidad}</div>
    </td>
  </tr>
))}
</table> */
}
