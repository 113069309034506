import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeUser: null,
  search: "",
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.activeUser = action.payload;
    },
    setSearchC(state, action) {
      state.search = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setCurrentUser, setSearchC, resetState } = userSlice.actions;

export default userSlice.reducer;
