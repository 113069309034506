import React from "react";
import { Modal, Button } from "react-bootstrap";
function formatDate(inputDate) {
  const date = new Date(inputDate);

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const year = String(date.getUTCFullYear()).slice(-2); // Get the last two digits of the year

  return `${hours}:${minutes} - ${day}:${month}:${year}`;
}
function UserInfoModal(props) {
  const Comunas = [
    "Cerrillos",
    "Cerro Navia",
    "Colina",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "María Pinto",
    "Ñuñoa",
    "Padre Hurtado",
    "Peñaflor",
    "Peñalolén",
    "Pirque",
    "Providencia",
    "Pudahuel",
    "Puente Alto",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Bernardo",
    "San Joaquín",
    "San Miguel",
    "San Pedro",
    "San Ramón",
    "Santiago",
    "Vitacura",
  ];
  console.log(props.data);
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Nombre:</strong> {props.data?.nombre}
        </p>
        <p>
          <strong>Apellido:</strong> {props.data?.apellido}
        </p>
        <p>
          <strong>Rut:</strong> {props.data?.rut}
        </p>
        <p>
          <strong>Correo:</strong> {props.data?.mail}
        </p>
        <p>
          <strong>Telefono:</strong> {props.data?.telefono}
        </p>
        <p>
          <strong>Instrucciones:</strong> {props.data?.instrucciones}
        </p>
        <p>
          <strong>comuna:</strong> {props.data?.comuna}
        </p>
        <p>
          <strong>sucursal:</strong> {props.data?.sucursal}
        </p>
        <p>
          <strong>Fecha Orden:</strong> {formatDate(props.data?.fecha_orden)}
        </p>
        <p>
          <strong>direccion:</strong>{" "}
          {`${props.data?.calle || ""} ${
            props.data?.numero ? props.data?.numero + "," : ""
          } ${
            props.data?.departamento ? ", " + props.data?.departamento : ""
          } ${props.data?.torre ? props.data?.torre : ""}`}
        </p>

        <p>
          <strong>Metodo:</strong>{" "}
          {props.data?.retiro === 1
                            ? "Retiro"
                            : Comunas.includes(props.data?.comuna)
                            ? "Envío particular"
                            : "Chilexpress"}
          {/* {props.data?.retiro == 1
            ? "Retiro"
            : props.data?.retiro == 0
            ? "Chilexpress"
            : `${
                props.data?.comuna &&
                props.data?.retiro == 0 &&
                "Envío particular"
              }`} */}
        </p>
        {/* Add more data fields as needed */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default UserInfoModal;
