import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import "./Cart.css"
import bander1 from '../Images/bander1.png'
import styles from './SecretLairCart.module.css'
import { RxCross2 } from 'react-icons/rx';
import CatlotusNav from '../Navbar/CatlotusNav';
import LowerNavbar from '../LowerNavbar/LowerNavbar';
import Footer from '../Footer/Footer';




const vehicleData = [
  {
    Carta1: "K’rrik, Son of Yawgmoth",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },
  {
    Carta1: "Helm of Awakening",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },
  {
    Carta1: "Izzet Charm",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },
  {
    Carta1: "K’rrik, Son of Yawgmoth",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },
  {
    Carta1: "Helm of Awakening",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },
  {
    Carta1: "Izzet Charm",
    Carta2: bander1,
    Set: "Foil Ichorr-E",
    Precio: "$ 11,300",
    Total: "$ 22,600",
  },


]



const SecretLairCart = () => {


  const [quantity, setQuantity] = useState(0)

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(prevCount => prevCount - 1);
    }
  }

  const handleIncrement = () => {
    if (quantity < Infinity) {
      setQuantity(prevCount => prevCount + 1);
    }
  }




  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className="cart">
        <div className={styles.cartInner}>
          <div className={styles.top}>
            <div className={styles.leftcarro}>Ir a carro de compras de Secret Lair</div>
            <div className={styles.rightCarro}>Ir a carro de compras de Secret Lair</div>
          </div>
<div className={styles.margLower}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tdd">
                  <TableCell align="right"><div className={styles.havetodes}>Nombre</div> </TableCell>
                  <TableCell align="right"><div className={styles.havetodes}>Tipo</div> </TableCell>
                  <TableCell align="right"><div className={styles.havetodes}>Cantidad</div> </TableCell>
                  <TableCell align="right"><div className={styles.havetodes}>Precio</div> </TableCell>
                  <TableCell align="right"><div className={styles.havetodes}>Total</div> </TableCell>
                  <TableCell align="right"><div className={styles.havetodes}></div> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicleData?.map((row, index) => (
                  <TableRow
                    key={row?.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right"><div className={styles.cartaArea}>
                      <div className={styles.cartAtext}>{row?.Carta1}</div>
                      <img src={row?.Carta2} className={styles.bander1} alt="" />
                    </div></TableCell>
                    <TableCell align="right">{row?.Set}</TableCell>
                    <TableCell align="right"><div className={styles.numberArea}>
                      <div className={styles.decremet} onClick={handleDecrement}>-</div>
                      <div className={styles.numberFielkd}>{quantity}</div>
                      <div className={styles.increment} onClick={handleIncrement}>+</div>
                    </div></TableCell>
                    <TableCell align="right">{row?.Precio}</TableCell>
                    <TableCell align="right">{row?.Total}</TableCell>
                    <TableCell align="right"><div className={styles.cross}><RxCross2 className={styles.crossInner} /></div></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          <div className={styles.pricArea}>
            <div className={styles.totalPargo}>Costo de envío: <span className={styles.dollar}>$3.000</span></div>
            <div className={styles.totalPargo1}>Total a pagar: <span className={styles.dollar}>$35.250</span></div>
            <button className={styles.comprar}>Comprar</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default SecretLairCart
